import "./product.css";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Input from "@mui/material/Input";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  deleteImageById,
  deleteProduct,
  getBrandById,
  getBrands,
  getCategories,
  getCategoryById,
  getDiscountById,
  getDiscounts,
  getProductById,
  updateProduct,
} from "../../redux/apiCalls";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function Product() {
  // const [productID, setProductID] = useState("");
  const [state, setState] = useState({});
  const [images, setImages] = useState([]);
  const [Category, setCategory] = useState([]);
  // const [tags, setTags] = useState([]);
  const [Brand, setBrand] = useState([]);
  const [Discount, setDiscount] = useState([]);

  const [available, setAvailable] = useState(false);

  useEffect(() => {
    // get product id from url
    const productID = window.location.pathname.split("/").pop();
    async function fetchData() {
      try {
        let res = await getProductById(productID);
        // console.log(res);
        if (res !== false && res !== null) {
          setState(res);
          setAvailable(true);
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, []);
  useEffect(() => {
    try {
      getCategoryById(state.category).then((data) => {
        setCategory(data.name_en);
      });
    } catch (err) {
      console.log(err);
    }
  }, [state.category]);

  useEffect(() => {
    try {
      getBrandById(state.brand).then((data) => {
        setBrand(data.name);
      });
    } catch (err) {
      console.log(err);
    }
  }, [state.brand]);

  useEffect(() => {
    try {
      if (state.discountID) {
        getDiscountById(state.discountID).then((data) => {
          setDiscount(data);
        });
      }
    } catch (err) {
      console.log(err);
    }
  }, [state.discountID]);

  useEffect(() => {
    try {
      if (state.images) {
        state.images.map(async (img) => {
          // check if image exists in the array of objects (images)
          if (images.find((image) => image.id === img.key) === undefined) {
            setImages((prev) => [...prev, { url: img.url, id: img.key }]);
          }
        });
      }
      // console.log(images);
    } catch (err) {
      console.log(err);
    }
  }, [state.images, images]);

  const handleDeleteImage = (e, id) => {
    e.preventDefault();
    // display confirmation dialog
    const confirm = window.confirm(
      "Are you sure you want to delete this image?"
    );
    if (confirm) {
      try {
        // check if this was the last image
        if (images.length === 1) {
          alert("You can't delete the last image");
        } else {
          deleteImageById(id);
          setImages(images.filter((image) => image.id !== id));
          setState({
            ...state,
            images: state.images.filter((img) => img.key !== id),
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  // const handleTags = (e) => {
  //   setTags(e.target.value.split(","));
  // };

  // ===========================================
  // ===========================================
  // ===========================================

  // EDIT PRODUCT
  const [editState, setEditState] = useState({});
  const [files, setFiles] = useState([]);
  const [editTags, setEditTags] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: { image: ["image/*"] },
      onDrop: (acceptedFiles, fileRejections) => {
        // check if there are any files rejected
        if (fileRejections.length > 0) {
          alert("You can only upload images");
        } else {
          const mappedFiles = acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          );
          setFiles((files) => [...files, ...mappedFiles]);
        }
      },
    });

  useEffect(() => {
    async function fetchCategories() {
      try {
        const res = await getCategories();
        if (res !== null) {
          setCategories(res);
        }
      } catch (err) {
        console.log(err);
      }
    }
    async function fetchBrands() {
      try {
        const res = await getBrands();
        if (res !== null) {
          setBrands(res);
        }
      } catch (err) {
        console.log(err);
      }
    }

    async function fetchDiscounts() {
      try {
        const res = await getDiscounts();
        if (res !== null) {
          // add empty option to the start of the array
          res.unshift({ _id: "none", name: "None" });

          setDiscounts(res);
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchCategories();
    fetchBrands();
    fetchDiscounts();
  }, []);
  // load images
  // const [count, setCount] = useState(0);
  // useEffect(() => {
  //   if (files.length > 0) {
  //     const mappedFiles = state.images.map((file) =>
  //       Object.assign(file, {
  //         preview: URL.createObjectURL(file),
  //       })
  //     );
  //     setFiles((files) => [...files, ...mappedFiles]);
  //     // load images from url as preview
  //     // NEEDS FIXING
  //     // const mappedFiles = state.images.map((file) =>
  //     //   Object.assign(file, {
  //     //     preview: file,
  //     //   })
  //     // );
  //     // setFiles((files) => [...files, ...mappedFiles]);
  //     console.log("ffff: "+files);
  //   }
  // }, [files]);
  const handleChange = (e) => {
    // console.log(editState);
    e.target.value.replace(/^\s+/g, "");

    setEditState((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleEditTags = (e) => {
    // trim spaces from the start
    const trimmed = e.target.value.replace(/^\s+/g, "");
    // check if text is empty
    if (trimmed === "") {
      setEditTags([]);
    } else {
      // split by comma
      setEditTags(e.target.value.split(","));
    }
  };

  const handleFileRemove = (e, file) => {
    e.preventDefault();
    setFiles(files.filter((item) => item !== file));
  };

  const handleClick = async (e) => {
    e.preventDefault();

    // check if form is empty

    let empty = true;
    // loop through editState and check if any value is empty
    for (const [value] of Object.entries(editState)) {
      if (value === "" || value === undefined || value === null) {
        // empty = true;
        // break;
      } else {
        empty = false;
      }
    }

    if (empty && files.length === 0 && editTags.length === 0) {
      alert("Please change at least one field");
      return;
    }

    // create a new product
    const product = {
      name_en: editState.name_en,
      name_ar: editState.name_ar,
      description_en: editState.description_en,
      description_ar: editState.description_ar,
      short_description_en: editState.short_description_en,
      short_description_ar: editState.short_description_ar,
      sku: editState.sku,
      code: editState.code ? editState.code : null,
      category: editState.category,
      brand: editState.brand,
      discountID: editState.discount,
      price: editState.price,
      quantity: editState.quantity,
      tags: editTags,
      // images: files,
    };
    // check if editTags is empty
    // editTags.length === 0 ? console.log("empty") : console.log("not empty");
    // if (editTags) console.log("tags: " + editTags);
    // console.log(editTags);
    // console.log(product.tags);
    const form = new FormData();
    form.append("id", state._id);
    if (product.name_en) form.append("name_en", product.name_en);
    if (product.name_ar) form.append("name_ar", product.name_ar);
    if (product.description_en)
      form.append("description_en", product.description_en);
    if (product.description_ar)
      form.append("description_ar", product.description_ar);
    if (product.short_description_en)
      form.append("short_description_en", product.short_description_en);
    if (product.short_description_ar)
      form.append("short_description_ar", product.short_description_ar);
    if (product.sku) form.append("sku", product.sku);
    if (product.code) form.append("code", product.code);
    if (product.category) form.append("category", product.category);
    if (product.brand) form.append("brand", product.brand);
    if (product.discountID) form.append("discountID", product.discountID);
    if (product.price) form.append("price", product.price);
    if (product.quantity) form.append("quantity", product.quantity);
    // add tags to form only if tags array is not empty
    if (
      editTags.length > 0 &&
      editTags !== null &&
      editTags !== undefined &&
      product.tags.length > 0
    ) {
      product.tags.map((tag) => form.append("tags[]", tag));
    }
    if (files.length > 0) {
      files.forEach((file) => {
        form.append("images", file);
      });
    }
    // form.append("name_ar", product.name_ar);
    // form.append("description_en", product.description_en);
    // form.append("description_ar", product.description_ar);
    // form.append("sku", product.sku);
    // form.append("category", product.category);
    // form.append("brand", product.brand);
    // form.append("discountID", product.discountID);
    // form.append("price", product.price);
    // form.append("quantity", product.quantity);
    // form.append("tags", product.tags);
    // files.map((file) => form.append("images", file));

    // form.append("images", files);

    // check if form is empty or not
    // if (form) console.log("form: " + form.entries().next().value);

    try {
      let res = await updateProduct(state._id, form);
      if (res !== undefined && res !== null && res !== "" && res !== false) {
        alert("Product Updated Successfully 😊");
        // reload page
        window.location.reload();
      } else {
        alert("Product not update 😔");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteProduct = async () => {
    try {
      console.log(state._id);
      console.log(state);
      let res = await deleteProduct(state._id);
      if (res !== undefined && res !== null && res !== "" && res !== false) {
        alert("Product Deleted Successfully! 😊");
        handleClose();
        window.location.replace("/products");
      } else {
        alert("Product not deleted 😔");
      }
    } catch (err) {
      console.log(err);
    }
  };
  // console.log(editState);
  // console.log(tags);
  // console.log(files);
  if (available) {
    return (
      <div className="Product">
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to delete this product?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
            <Button onClick={handleDeleteProduct} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <div className="ProductTitleContainer">
          <h1 className="ProductTitle">Product</h1>
          <button
            className="productDeleteButton"
            onClick={() => handleClickOpen()}
          >
            Delete
          </button>
        </div>
        {/*
      =================================================================
      Display the product's current data 
      =================================================================
      */}
        <div className="productShow">
          <div className="productShowTop">
            <div className="productShowTopImages">
              {images &&
                images.map((image) => (
                  <div className="productShowImageContainer">
                    <img
                      className="productShowImage"
                      src={image.url}
                      alt="product"
                    />
                    <button onClick={(e) => handleDeleteImage(e, image.id)}>
                      REMOVE
                    </button>
                  </div>
                ))}
            </div>
            <span className="productShowId">{state._id}</span>
          </div>
          <div className="productShowMiddle">
            {/* The english side */}
            <div className="productShowMiddleLeft">
              <span className="productShowName">
                Product name: <b>{state.name_en}</b>
              </span>
              <br />
              <span className="productShowDescription">
                Product description: <b>{state.description_en}</b>
              </span>
              <br />
              <span className="productShowShortDescription">
                Product short description: <b>{state.short_description_en}</b>
              </span>
            </div>
            {/* The arabic side */}
            <div className="productShowMiddleRight">
              <span className="productShowName">
                اسم المنتج: <b>{state.name_ar}</b>
              </span>
              <br />
              <span className="productShowDescription">
                وصف المنتج: <b>{state.description_ar}</b>
              </span>
              <br />
              <span className="productShowShortDescription">
                وصف مختصر للمنتج: <b>{state.short_description_ar}</b>
              </span>
            </div>
          </div>
          <div className="productShowBottom">
            <span className="productShowSKU">
              SKU: <b>{state.sku}</b>
            </span>
            <span className="productShowCODE">
              Code: <b>{state.code}</b>
            </span>
            <div className="productShowRow">
              <span className="productShowCategory">
                Category: <b>{Category}</b>
              </span>
              <span className="productShowBrand">
                Brand: <b>{Brand}</b>
              </span>
              {
                // if there is a discount
                state.discountID && (
                  <span className="productShowDiscount">
                    Discount:{" "}
                    <b>
                      {Discount.name === "none" || Discount.name === "None" ? (
                        <>No Discount</>
                      ) : (
                        <>
                          {Discount.name} -- {Discount.discount_percentage}%
                        </>
                      )}
                    </b>
                  </span>
                )
              }
            </div>
            <div className="productShowRow">
              <span className="productShowPrice">
                Price: <b>{state.price}</b>
              </span>
              <span className="productShowTotalPrice">
                Price after discount: <b>{state.TotalPrice}</b>
              </span>
              <span className="productShowQuantity">
                Quantity: <b>{state.quantity}</b>
              </span>
              <span className="productShowTags">
                Tags:{" "}
                <b>
                  {state.tags &&
                    state.tags.map((tag) => (
                      <span className="productShowTag">{tag}</span>
                    ))}
                </b>
              </span>
            </div>
            <span className="productShowInfo">
              Created At:{" "}
              <b>{new Date(state.createdAt).toLocaleString("en-AE")}</b>
            </span>
            <span className="productShowInfo">
              Updated At:{" "}
              <b>{new Date(state.updatedAt).toLocaleString("en-AE")}</b>
            </span>
          </div>
        </div>
        {/* 
      =================================================================
      Edit the product's data 
      =================================================================
      */}
        <div className="ProductTitleContainer">
          <h1 className="ProductTitle">Edit</h1>
        </div>
        <div className="productEdit">
          <form className="productEditForm">
            <div className="productEditTop">
              <div className="productEditImageUpload">
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  <p>Drag 'n' drop some files here, or click to select files</p>
                </div>
                <aside>
                  <h2>Files</h2>
                  <ul className="fileList">
                    {files.map((file) => (
                      <li key={file.path}>
                        <img src={file.preview} alt="product" />
                        <span>
                          {file.path} - {file.size} bytes
                        </span>
                        <button onClick={(e) => handleFileRemove(e, file)}>
                          REMOVE
                        </button>
                      </li>
                    ))}
                  </ul>
                </aside>
              </div>
            </div>
            <div className="productEditMiddle">
              {/* The english side */}
              <div className="productEditMiddleLeft">
                <div className="productEditItem">
                  <label>Product name:</label>
                  <TextField
                    id="productEditName"
                    placeholder="iPhone 13"
                    name="name_en"
                    value={editState.name_en}
                    onChange={handleChange}
                    margin="normal"
                    variant="standard"
                    className="productEditInput"
                  />
                </div>
                <div className="productEditItem">
                  <label>Product description:</label>
                  <TextField
                    id="productEditDescription"
                    placeholder="new iPhone 13 128GB storage Green"
                    name="description_en"
                    value={editState.description_en}
                    onChange={handleChange}
                    multiline
                    margin="normal"
                    variant="standard"
                    className="productEditInput"
                  />
                </div>
                <div className="productEditItem">
                  <label>Product short description:</label>
                  <TextField
                    id="productEditShortDescription"
                    placeholder="new iPhone 13"
                    name="short_description_en"
                    value={editState.short_description_en}
                    onChange={handleChange}
                    multiline
                    margin="normal"
                    variant="standard"
                    className="productEditInput"
                  />
                </div>
              </div>
              {/* The arabic side */}
              <div className="productEditMiddleRight">
                <div className="productEditItem">
                  <label>اسم المنتج:</label>
                  <TextField
                    id="productEditName"
                    placeholder="ايفون 13"
                    name="name_ar"
                    value={editState.name_ar}
                    onChange={handleChange}
                    margin="normal"
                    variant="standard"
                    className="productEditInput"
                  />
                </div>
                <div className="productEditItem">
                  <label>وصف المنتج:</label>
                  <TextField
                    id="productEditDescription"
                    placeholder="جهاز ايفون 13 جديد مساحة تخزين 128 غيغا أخضر"
                    name="description_ar"
                    value={editState.description_ar}
                    onChange={handleChange}
                    multiline
                    margin="normal"
                    variant="standard"
                    className="productEditInput"
                  />
                </div>
                <div className="productEditItem">
                  <label>وصف مختصر:</label>
                  <TextField
                    id="productEditShortDescription"
                    placeholder="ايفون 13 جديد"
                    name="short_description_ar"
                    value={editState.short_description_ar}
                    onChange={handleChange}
                    multiline
                    margin="normal"
                    variant="standard"
                    className="productEditInput"
                  />
                </div>
              </div>
            </div>
            <div className="productEditBottom">
              <div className="productEditItem">
                <label>SKU:</label>
                <Input
                  id="productEditSKU"
                  placeholder="SKU"
                  name="sku"
                  value={editState.sku}
                  onChange={handleChange}
                  margin="normal"
                  variant="standard"
                  className="productEditInput"
                />
              </div>
              <div className="productEditItem">
                <label className="productEditCode">CODE:</label>
                <Input
                  id="productEditCode"
                  placeholder="XXXXX"
                  name="code"
                  value={editState.code}
                  onChange={handleChange}
                  className="productEditCode"
                />
              </div>
              <div className="productEditRow">
                <div className="productEditItem">
                  <label>Category:</label>
                  <select
                    id="productEditCategory"
                    className="productEditInput"
                    name="category"
                    onChange={handleChange}
                  >
                    <option value=""></option>
                    {categories.map((category) => (
                      <option key={category._id} value={category._id}>
                        {category.name_en}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="productEditItem">
                  <label>Brands:</label>
                  <select
                    id="productEditBrands"
                    className="productEditInput"
                    name="brand"
                    onChange={handleChange}
                  >
                    <option value=""></option>
                    {brands.map((brand) => (
                      <option key={brand._id} value={brand._id}>
                        {brand.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="productEditItem">
                  <label>Discount:</label>
                  <select
                    id="productEditDiscount"
                    className="productEditInput"
                    name="discount"
                    onChange={handleChange}
                  >
                    <option value=""></option>
                    {discounts.map((discount) => (
                      <option key={discount._id} value={discount._id}>
                        {discount.name}
                        {" - "}
                        {discount.discount_percentage}%
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="productEditRow">
                <div className="productEditItem">
                  <label className="productEditPrice">Price:</label>
                  <Input
                    id="productEditPrice"
                    placeholder="2999.99"
                    name="price"
                    value={editState.price}
                    onChange={handleChange}
                    type="number"
                    startAdornment={
                      <InputAdornment position="start">AED</InputAdornment>
                    }
                    className="productEditPrice"
                  />
                </div>
                <div className="productEdittItem">
                  <label>Tags:</label>
                  <TextField
                    id="productEditTags"
                    placeholder="iphone,phones,mobile,new"
                    name="tags"
                    value={editState.tags}
                    onChange={handleEditTags}
                    multiline
                    margin="normal"
                    variant="standard"
                    className="productEditInput"
                  />
                </div>
              </div>
              <div className="productEditItem">
                <label className="productEditQuantity">Quantity:</label>
                <Input
                  id="productEditQuantity"
                  placeholder="11"
                  name="quantity"
                  value={editState.quantity}
                  onChange={handleChange}
                  type="number"
                  className="productEditQuantity"
                />
              </div>
              <input
                type="submit"
                value="Submit"
                onClick={handleClick}
                className="productEditSubmit"
              />
            </div>
          </form>
        </div>
      </div>
    );
  } else {
    return (
      <div className="Product">
        <h1>Product not found</h1>
      </div>
    );
  }
}

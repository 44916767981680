import "./productList.css";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import {
  getCategoryById,
  getProducts,
  searchProducts,
} from "../../redux/apiCalls";
import { Input } from "@mui/material";

const columns = [
  { field: "_id", headerName: "ID", width: 90 },
  {
    field: "images",
    headerName: "Image",
    sortable: false,
    width: 90,
    renderCell: (rowData) => (
      <img
        className="productListImg"
        src={rowData.row.images[0] ? rowData.row.images[0].url : ""}
        alt={rowData.row.name_en}
      />
    ),
  },
  { field: "code", headerName: "Code", width: 100 },
  { field: "name_en", headerName: "Name En", width: 150 },
  { field: "name_ar", headerName: "Name Ar", width: 150 },
  // { field: "description_en", headerName: "Description En", width: 150 },
  // { field: "description_ar", headerName: "Description Ar", width: 150 },
  {
    field: "category",
    headerName: "Category",
    width: 150,
    renderCell: (rowData) => {
      const category = GetCategory(rowData.row.category);
      return <p>{category}</p>;
    },
  },
  // { field: "tags", headerName: "Tags", width: 150 },
  { field: "price", headerName: "Price", width: 100 },
  { field: "quantity", headerName: "Quantity", width: 100 },
  {
    field: "createdAt",
    headerName: "Created At",
    width: 150,
    valueFormatter: (params) => {
      return new Date(params.value).toLocaleDateString("en-AE");
    },
  },
  {
    field: "updatedAt",
    headerName: "Updated At",
    width: 150,
    valueFormatter: (params) => {
      return new Date(params.value).toLocaleDateString("en-AE");
    },
  },
  {
    field: "actions",
    headerName: "Actions",
    sortable: false,
    width: 100,
    renderCell: (rowData) => {
      return (
        <div className="actions">
          <Link to={"/product/" + rowData.row._id}>
            <EditIcon className="productListEdit" />
          </Link>
        </div>
      );
    },
  },
];

function GetCategory(catID) {
  const [category, setCategory] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await getCategoryById(catID);
        if (res !== null) {
          setCategory(res.name_en);
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, [catID]);

  return category;
}

export default function ProductList() {
  // get data from api using axios
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await getProducts();
        if (res !== null) {
          setData(res);
          setLoading(false);
        } else {
          setLoading(true);
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, []);

  async function handleSearch(value) {
    // if value is empty, trigger the useEffect to get all products
    if (value === "") {
      const res = await getProducts();
      if (res !== null) {
        setData(res);
        setLoading(false);
      } else {
        setLoading(true);
      }
    } else {
      const res = await searchProducts(value);
      if (res !== null) {
        setData(res);
        setLoading(false);
      } else {
        setLoading(true);
      }
    }
  }

  return (
    <div className="ProductList">
      <div className="productListTop">
        <h2>Products</h2>
        <Input
          type="text"
          placeholder="Search"
          className="ListSearch"
          onChange={(e) => handleSearch(e.target.value)}
        />
        <Link to={`/newProduct`}>
          <button className="productAddButton">New Product</button>
        </Link>
      </div>
      <DataGrid
        rows={loading ? [] : data}
        columns={columns}
        // autoPageSize={true}
        checkboxSelection
        disableSelectionOnClick
        getRowId={(row) => row._id}
        rowsPerPageOptions={[10, 20, 50, 100]}
      />
    </div>
  );
}

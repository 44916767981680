import { useEffect, useState } from "react";
import { getAboutUs, updateAboutUs } from "../../redux/apiCalls";
import "./aboutUs.css";

export default function AboutUs() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await getAboutUs();
        if (res !== null) {
          setData(res);
          setLoading(false);
        } else {
          setData(null);
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, []);

  const [editData, setEditData] = useState({});
  // useEffect(() => {
  // if (data.description) {
  // setEditData(data.description);
  // }
  // }, [data.description]);
  async function updateAU() {
    const res = await updateAboutUs(editData);
    if (res != null && res !== false) {
      console.log(res);
      alert("About Us updated successfully");
    } else {
      alert("Failed to update About Us");
    }
  }

  const handleEditChange = (e) => {
    setEditData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  console.log(editData);
  return (
    <div className="TermsAndConditions">
      <div className="termsAndConditionsView">
        <h3 className="TermsAndConditionsTitle">About Us</h3>
        <ul className="TermsAndConditionsList">
          {loading ? (
            <div className="loading">
              {/* {updatePP({
                name: "privacy",
                description:
                  "This is the Privacy Policy page. You can edit this page by clicking the edit button below.",
              })} */}
              loading...
            </div>
          ) : (
            <span className="TermsAndConditionsListItem">
              {data.description_en}
              <br />
              <br />
              <br />
              {data.description_ar}
            </span>
          )}
        </ul>
      </div>
      <div className="termsAndConditionsEdit">
        <h3 className="TermsAndConditionsTitle">Edit About Us</h3>

        <div className="TermsAndConditionsEditArea">
          <textarea
            className="TermsAndConditionsEditInput"
            placeholder="Enter your Privacy Policy here..."
            name="description_en"
            value={editData.description}
            onChange={handleEditChange}
          />
          <textarea
            className="TermsAndConditionsEditInput"
            placeholder="اكتب سياسة الخصوصية هنا ..."
            name="description_ar"
            value={editData.description}
            onChange={handleEditChange}
          />
          <button
            className="TermsAndConditionsEditButton"
            onClick={() => updateAU()}
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
}

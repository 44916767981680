import "./featuredProductsList.css";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import { getFeaturedProducts, getProductById } from "../../redux/apiCalls";

const columns = [
  { field: "_id", headerName: "ID", width: 90 },
  {
    field: "image",
    headerName: "Image",
    sortable: false,
    width: 90,
    renderCell: (rowData) => (
      <img
        className="featuredProductsListImg"
        src={rowData.row.image ? rowData.row.image.url : ""}
        alt={rowData.row.name}
      />
    ),
  },
  {
    field: "product_id",
    headerName: "Product ID",
    width: 150,
    renderCell: (rowData) => (
      <Link to={"/product/" + rowData.row.product_id}>
        {GetProductName(rowData.row.product_id)}
      </Link>
    ),
  },

  { field: "title_en", headerName: "Title En", width: 150 },
  { field: "title_ar", headerName: "Title Ar", width: 150 },
  { field: "description_en", headerName: "Description En", width: 150 },
  { field: "description_ar", headerName: "Description Ar", width: 150 },
  {
    field: "createdAt",
    headerName: "Created At",
    width: 150,
    valueFormatter: (params) => {
      return new Date(params.value).toLocaleDateString("en-AE");
    },
  },
  {
    field: "updatedAt",
    headerName: "Updated At",
    width: 150,
    valueFormatter: (params) => {
      return new Date(params.value).toLocaleDateString("en-AE");
    },
  },
  {
    field: "actions",
    headerName: "Actions",
    sortable: false,
    width: 100,
    renderCell: (rowData) => {
      return (
        <div className="actions">
          <Link to={"/featuredProduct/" + rowData.row._id}>
            <EditIcon className="featuredProductsListEdit" />
          </Link>
        </div>
      );
    },
  },
];

function GetProductName(id) {
  const [name, setName] = useState(null);
  useEffect(() => {
    const getName = async () => {
      const res = await getProductById(id);
      if (res != null) {
        setName(res.name_en);
      }
    };
    getName();
  }, [id]);
  return name;
}

export default function FeaturedProductsList() {
  // get data from api using axios
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await getFeaturedProducts();
        if (res != null) {
          setData(res);
          setLoading(false);
        } else {
          setLoading(true);
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, []);

  return (
    <div className="FeaturedProductsList">
      <div className="featuredProductsListTop">
        <h2>Featured Products</h2>
        <Link to={`/newFeaturedProduct`}>
          <button className="featuredProductsListAddButton">
            New Featured Product
          </button>
        </Link>
      </div>
      <DataGrid
        rows={loading ? [] : data}
        columns={columns}
        autoPageSize={true}
        checkboxSelection
        disableSelectionOnClick
        getRowId={(row) => row._id}
      />
    </div>
  );
}

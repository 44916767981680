import { useEffect, useState } from "react";
import "./configs.css";
import { Input, InputAdornment } from "@mui/material";
import { getConfigs, updateConfigs } from "../../redux/apiCalls";

export default function Configs() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await getConfigs("delivery_charges");
        if (res !== null) {
          setData(res);
          setLoading(false);
        } else {
          setData(null);
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, []);

  const [editData, setEditData] = useState({});
  // useEffect(() => {
  // if (data.description) {
  // setEditData(data.description);
  // }
  // }, [data.description]);
  async function handleUpdate() {
    const data = {
      name: "delivery_charges",
      value: editData.delivery_charges,
    };
    const res = await updateConfigs(data);
    if (res !== undefined && res !== null && res !== "" && res !== false) {
      console.log(res);
      alert("Configs updated successfully");
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    } else {
      alert("Failed to update Configs");
    }
  }

  const handleEditChange = (e) => {
    setEditData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  return (
    <div className="Configs">
      <div className="ConfigsView">
        <h3 className="ConfigsTitle">Configs</h3>
        <ul className="ConfigsList">
          {loading ? (
            <div className="loading">
              {/* {updatePP({
                name: "privacy",
                description:
                  "This is the Privacy Policy page. You can edit this page by clicking the edit button below.",
              })} */}
              loading...
            </div>
          ) : (
            <>
              <h3>Delivery Charges</h3>
              <span className="ConfigsListItem">{data.value} AED</span>
            </>
          )}
        </ul>
      </div>
      <div className="ConfigsEdit">
        <h3 className="ConfigsTitle">Edit Configs</h3>

        <div className="ConfigsEditArea">
          <label>Delivery Charges</label>
          <Input
            id="configsEditDeliveryCharges"
            placeholder="30"
            name="delivery_charges"
            value={editData.delivery_charges}
            onChange={handleEditChange}
            type="number"
            startAdornment={
              <InputAdornment position="start">AED</InputAdornment>
            }
            className="ConfigsEditPrice"
          />

          <div className="buttonContainer">
            <button
              className="ConfigsEditButton"
              onClick={() => handleUpdate()}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

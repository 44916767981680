import { useEffect, useState } from "react";
import { getOrderById, updateOrderStatus } from "../../redux/apiCalls";
import "./order.css";

export default function Order() {
  const [state, setState] = useState({});

  const [available, setAvailable] = useState(false);

  useEffect(() => {
    // get order id from url
    const orderID = window.location.pathname.split("/").pop();
    async function fetchData() {
      try {
        let res = await getOrderById(orderID);
        console.log(res);
        if (res !== false && res !== null) {
          setState(res);
          setAvailable(true);
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, []);

  // useEffect(() => {
  //   async function getProductName(id) {
  //     console.log("oo" + id);
  //     let res = await getProductById(id);
  //     if (res !== null) {
  //       console.log("res");
  //       console.log(res);
  //       return res.name_en;
  //     } else {
  //       return "Product not found";
  //     }
  //   }
  //   if (state.products) {
  //     let temp = [];
  //     state.products.map(async (item) => {
  //       let name = await getProductName(item.product_id);
  //       console.log(name);
  //       temp.push({ name: name, quantity: item.quantity });
  //       // setProducts(...products, temp);
  //       // console.log("pppp");
  //       // console.log(products);
  //     });
  //     setProducts(temp);
  //     console.log("temp");
  //     console.log(products);
  //     // setState({ ...state, products: temp });
  //   }
  // }, [state]);

  //   useEffect(() => {
  // console.log(products);
  //   }, [products.name]);

  // ===========================================
  // ===========================================
  // ===========================================

  // EDIT ORDER
  const [status, setStatus] = useState(null);

  const handleStatus = (e) => {
    setStatus(e.target.value);
  };

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      let res = await updateOrderStatus(state._id, status);
      if (res !== undefined && res !== null && res !== "" && res !== false) {
        console.log(res);
        alert("Order Status updated successfully");
        window.location.reload();
      } else {
        alert("Order Status update failed");
      }
    } catch (err) {
      console.log(err);
    }
  };

  if (available) {
    return (
      <div className="Order">
        <div className="orderWrapper">
          <div className="orderTitleContainer">
            <h1 className="orderTitle">Order</h1>
          </div>
          <div className="orderTop">
            <div className="orderTopLeft">
              <div className="orderInfoTop">
                <span className="orderInfoKey">Order ID:</span>
                <span className="orderInfoValue">{state._id}</span>
              </div>
              <div className="orderInfoTop">
                <span className="orderInfoKey">User Email:</span>
                <span className="orderInfoValue">{state.user_email}</span>
              </div>
              <div className="orderInfoTop">
                <span className="orderInfoKey">Order Date:</span>
                <span className="orderInfoValue">
                  {new Date(state.createdAt).toLocaleString("en-AE")}
                </span>
              </div>
              <div className="orderInfoTop">
                <span className="orderInfoKey">Order Updated:</span>
                <span className="orderInfoValue">
                  {new Date(state.updatedAt).toLocaleString("en-AE")}
                </span>
              </div>
            </div>
            <div className="orderTopRight">
              <div className="orderInfoTop">
                <span className="orderInfoKey">Order Status:</span>
                <span className="orderInfoValue">{state.status}</span>
              </div>
              <div className="orderInfoTop">
                <span className="orderInfoKey">Order Total:</span>
                <span className="orderInfoValue">AED {state.amount}</span>
              </div>
            </div>
          </div>
          <div className="orderBottom">
            <div className="orderBottomLeft">
              {state.products &&
                state.products.map((item) => (
                  <div className="orderProduct">
                    <div className="orderProductInfo">
                      <span className="orderProductName">
                        <a href={"/product/" + item.product_id}>
                          {item.name_en}
                        </a>
                      </span>
                      <span className="orderProductQuantity">
                        Quantity: {item.quantity}
                      </span>
                      <span className="orderProductPrice">
                        Price: AED {item.TotalPrice}
                      </span>
                    </div>
                  </div>
                ))}
            </div>
            <div className="orderBottomRight">
              <div className="orderInfoBottom">
                <span className="orderInfoKey">Order Details:</span>
                <span className="orderInfoValue">
                  <br />
                  Name: <b>{state.shipping_address.name}</b> <br />
                  Phone: <b>{state.shipping_address.phone}</b> <br />
                  Address:{" "}
                  <b>
                    {state.shipping_address.city +
                      ", " +
                      state.shipping_address.street}
                  </b>{" "}
                  <br />
                </span>
              </div>
              <div className="orderInfoBottom">
                <span className="orderInfoKey">Payment Method:</span>
                <span className="orderInfoValue">{state.paymentOption}</span>
              </div>
            </div>
          </div>
        </div>

        {/* EDIT */}

        <div className="orderWrapper">
          <div className="orderTitleContainer">
            <h1 className="orderTitle">Edit</h1>
          </div>
          <div className="orderEdit">
            <div className="orderStatus">
              <label>Order Status</label>
              <select
                name="orderStatusOptions"
                id="orderStatus"
                onChange={handleStatus}
              >
                {state.status === "pending" ? (
                  <>
                    <option>Select</option>
                    <option value="approved">Approved</option>
                    <option value="declined">Declined</option>
                  </>
                ) : state.status === "approved" ? (
                  <>
                    <option>Select</option>
                    <option value="pending">Pending</option>
                    <option value="declined">Declined</option>
                  </>
                ) : state.status === "declined" ? (
                  <>
                    <option>Select</option>
                    <option value="pending">Pending</option>
                    <option value="approved">Approved</option>
                  </>
                ) : (
                  <>
                    <option>Select</option>
                    <option value="pending">Pending</option>
                    <option value="approved">Approved</option>
                    <option value="declined">Declined</option>
                  </>
                )}
              </select>
            </div>
            <div className="orderEditButtonContainer">
              <button
                className="orderEditButton"
                onClick={handleClick}
                disabled={status === null}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="Order">
        <div className="orderTitleContainer">
          <h1 className="orderTitle">Category</h1>
        </div>
        <h3 className="">Category not found</h3>
      </div>
    );
  }
}

import "./newCategory.css";

import { useState } from "react";
import { TextField } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { createCategory } from "../../redux/apiCalls";

export default function NewCategory() {
  const [state, setState] = useState({});
  const [image, setImage] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: { image: ["image/*"] },
      multiple: false,
      onDrop: (acceptedFiles, fileRejections) => {
        // check if file is rejected or not
        if (fileRejections.length > 0) {
          alert("Please upload an image file");
          return;
        } else {
          setImage(
            Object.assign(acceptedFiles[0], {
              preview: URL.createObjectURL(acceptedFiles[0]),
            })
          );
        }
      },
    });

  const handleChange = (e) => {
    e.target.value.replace(/^\s+/g, "");

    setState((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleFileRemove = (e, file) => {
    e.preventDefault();
    setImage(null);
  };

  const handleClick = async (e) => {
    e.preventDefault();

    // check if form is empty

    let empty = true;
    // loop through editState and check if any value is empty
    for (const [value] of Object.entries(state)) {
      if (value === "" || value === undefined || value === null) {
        empty = true;
        // break;
      } else {
        // empty = false;
      }
    }

    if (empty && image === null) {
      alert("Please Fill all fields and add at least one image and one tag");
      return;
    }

    // check if all fields are filled
    if (
      state.name_en &&
      state.name_ar &&
      state.description_en &&
      state.description_ar &&
      image !== null
    ) {
      // create a new product
      const product = {
        name_en: state.name_en,
        name_ar: state.name_ar,
        description_en: state.description_en,
        description_ar: state.description_ar,
      };

      const form = new FormData();
      if (product.name_en) form.append("name_en", product.name_en);
      if (product.name_ar) form.append("name_ar", product.name_ar);
      if (product.description_en)
        form.append("description_en", product.description_en);
      if (product.description_ar)
        form.append("description_ar", product.description_ar);
      if (image !== null) {
        form.append("images", image);
      }

      try {
        let res = await createCategory(form);
        if (res !== undefined && res !== null && res !== "" && res !== false) {
          alert("Category Created Successfully 😊");
          window.location.replace("/categories");
        } else {
          alert("Category not created 😔");
        }
      } catch (err) {
        console.log("Category not created 😔");
      }
    } else {
      alert("Please fill all fields 🙂");
    }
  };
  // console.log(state);
  // console.log(files);

  return (
    <div className="NewCategory">
      <form className="newCategoryForm">
        <div className="newProductImageUpload">
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop some files here, or click to select files</p>
          </div>
          <aside>
            <h2>File</h2>
            <ul className="fileList">
              {image && (
                <li key={image}>
                  <img src={image.preview} alt="brand" />
                  <span>
                    {image.path} - {image.size} bytes
                  </span>
                  <button onClick={(e) => handleFileRemove(e, image)}>
                    REMOVE
                  </button>
                </li>
              )}
            </ul>
          </aside>
        </div>
        <div className="newCategoryMiddle">
          {/* The english side */}
          <div className="newCategoryMiddleLeft">
            <div className="newCategoryItem">
              <label>Category name:</label>
              <TextField
                id="newCategoryName"
                placeholder="Phones"
                name="name_en"
                value={state.name_en}
                onChange={handleChange}
                margin="normal"
                variant="standard"
                className="newCategoryInput"
              />
            </div>
            <div className="newCategoryItem">
              <label>Category description:</label>
              <TextField
                id="newCategoryDescription"
                placeholder="Mobile devices"
                name="description_en"
                value={state.description_en}
                onChange={handleChange}
                multiline
                margin="normal"
                variant="standard"
                className="newCategoryInput"
              />
            </div>
          </div>
          {/* The arabic side */}
          <div className="newCategoryMiddleRight">
            <div className="newCategoryItem">
              <label>اسم الصنف:</label>
              <TextField
                id="newCategoryName"
                placeholder="موبايلات"
                name="name_ar"
                value={state.name_ar}
                onChange={handleChange}
                margin="normal"
                variant="standard"
                className="newCategoryInput"
              />
            </div>
            <div className="newCategoryItem">
              <label>وصف الصنف:</label>
              <TextField
                id="newCategoryDescription"
                placeholder="أجهزة موبايلات"
                name="description_ar"
                value={state.description_ar}
                onChange={handleChange}
                multiline
                variant="standard"
                margin="normal"
                className="newCategoryInput"
              />
            </div>
          </div>
        </div>
        <div className="newCategoryBottom">
          <input
            type="submit"
            value="Submit"
            className="newCategorySubmit"
            onClick={handleClick}
          />
        </div>
      </form>
    </div>
  );
}

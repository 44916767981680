import "./user.css";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PhoneIcon from "@mui/icons-material/Phone";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import {
  blockUser,
  deleteUser,
  getUserById,
  makeUserAdmin,
  removeAdmin,
  unblockUser,
  updateUser,
} from "../../redux/apiCalls";

export default function User() {
  // const [userID, setUserID] = useState("");
  const [state, setState] = useState({});

  const [available, setAvailable] = useState(false);

  useEffect(() => {
    const CategoryId = window.location.pathname.split("/").pop();
    async function fetchData() {
      try {
        let res = await getUserById(CategoryId);
        console.log(res);
        if (res !== false && res !== null) {
          setState(res);
          setAvailable(true);
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, []);

  // ===========================================
  // ===========================================
  // ===========================================

  // EDIT CATEGORY

  const [editState, setEditState] = useState({});

  const handleEditChange = (e) => {
    setEditState((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleShippingAddressChange = (e) => {
    setEditState((prevState) => {
      return {
        ...prevState,
        shippingAddress: {
          ...prevState.shippingAddress,
          [e.target.name]: e.target.value,
        },
      };
    });
  };

  const handleClick = async (e) => {
    e.preventDefault();
    const user = {
      name: editState.name,
      phoneNumber: editState.phoneNumber,
      shippingAddress: editState.shippingAddress,
    };
    try {
      let res = await updateUser(state._id, user);
      if (res !== undefined && res !== null && res !== "" && res !== false) {
        console.log(res);
        alert("User updated successfully");
        window.location.reload();
      } else {
        alert("User update failed");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleBlock = async (e) => {
    e.preventDefault();
    try {
      let res = await blockUser(state._id);
      if (res !== undefined && res !== null && res !== "" && res !== false) {
        console.log(res);
        alert("User blocked successfully");
        window.location.reload();
      } else {
        alert("User block failed");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleUnBlock = async (e) => {
    e.preventDefault();
    try {
      let res = await unblockUser(state._id);
      if (res !== undefined && res !== null && res !== "" && res !== false) {
        console.log(res);
        alert("User unblocked successfully");
        window.location.reload();
      } else {
        alert("User unblock failed");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      let res = await deleteUser(state._id);
      if (res !== undefined && res !== null && res !== "" && res !== false) {
        console.log(res);
        alert("User deleted successfully");
        window.location.replace("/users");
      } else {
        alert("User delete failed");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleMakeAdmin = async (e) => {
    e.preventDefault();
    try {
      let res = await makeUserAdmin(state.email);
      if (res !== undefined && res !== null && res !== "" && res !== false) {
        console.log(res);
        alert("User made admin successfully");
        window.location.reload();
      } else {
        alert("User make admin failed");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleRemoveAdmin = async (e) => {
    e.preventDefault();
    try {
      let res = await removeAdmin(state.email);
      if (res !== undefined && res !== null && res !== "" && res !== false) {
        console.log(res);
        alert("Admin removed successfully");
        window.location.reload();
      } else {
        alert("Admin remove failed");
      }
    } catch (err) {
      console.log(err);
    }
  };

  if (available) {
    return (
      <div className="user">
        <div className="userTitleContainer">
          <h1 className="userTitle">User</h1>
          <div className="userTitleContainerRight">
            {state.isAdmin ? (
              <button
                className="userMakeAdminButton"
                onClick={handleRemoveAdmin}
              >
                Make User
              </button>
            ) : (
              <button className="userMakeAdminButton" onClick={handleMakeAdmin}>
                Make Admin
              </button>
            )}
            {state.isBlocked ? (
              <button className="userBlockButton" onClick={handleUnBlock}>
                Unblock
              </button>
            ) : (
              <button className="userBlockButton" onClick={handleBlock}>
                Block user
              </button>
            )}
            <button className="userDeleteButton" onClick={handleDelete}>
              Delete user
            </button>
          </div>
        </div>
        <div className="userContainer">
          {/* Display the user's current data */}
          <div className="userShow">
            <div className="userShowTop">
              <span className="userShowUsername">{state.name}</span>
              <span className="userShowEmail">{state.email}</span>
              <span className="userShowId">Id: {state._id}</span>
            </div>
            <div className="userShowBottom">
              <span className="userShowTitle">Account Detailes</span>

              <div className="userShowInfo">
                <AccountCircleIcon className="userShowIcon" />
                <span className="userShowInfoTitle">
                  Provider: <b>{state.provider}</b>
                </span>
              </div>
              <div className="userShowInfo">
                <AccountCircleIcon className="userShowIcon" />
                <span className="userShowInfoTitle">
                  Provider Id: <b>{state.providerId}</b>
                </span>
              </div>
              <div className="userShowInfo">
                <VerifiedUserIcon className="userShowIcon" />
                <span className="userShowInfoTitle">
                  Verified: <b>{state.isVerified ? "True" : "False"}</b>
                </span>
              </div>
              <div className="userShowInfo">
                <AccessTimeIcon className="userShowIcon" />
                <span className="userShowInfoTitle">
                  Created At:&nbsp;
                  <b>{new Date(state.createdAt).toLocaleString("en-AE")}</b>
                </span>
              </div>
              <div className="userShowInfo">
                <AccessTimeIcon className="userShowIcon" />
                <span className="userShowInfoTitle">
                  Updated At:&nbsp;
                  <b>{new Date(state.updatedAt).toLocaleString("en-AE")}</b>
                </span>
              </div>

              <span className="userShowTitle">Contact Detailes</span>
              <div className="userShowInfo">
                <PhoneIcon className="userShowIcon" />
                <span className="userShowInfoTitle">
                  Phone number:&nbsp;
                  <b>
                    {state.phoneNumber ? state.phoneNumber : "Not provided"}
                  </b>
                </span>
              </div>
              <div className="userShowInfo">
                <LocalShippingIcon className="userShowIcon" />
                <span className="userShowInfoTitle">
                  Shipping Address:&nbsp;
                  <b>
                    {state.shippingAddress ? (
                      <>
                        {state.shippingAddress.street ? (
                          <>
                            {state.shippingAddress.city +
                              ", " +
                              state.shippingAddress.street}
                            &nbsp;
                          </>
                        ) : (
                          <>{state.shippingAddress.city + ", "}</>
                        )}
                      </>
                    ) : (
                      "Not provided"
                    )}
                  </b>
                </span>
              </div>
            </div>
          </div>
          {/* Edit the user's data */}
          <div className="userEdit">
            <span className="userEditTitle">Edit</span>
            <form className="userEditForm">
              <div className="userEditItem">
                <label>Username</label>
                <TextField
                  id="userEditUsername"
                  placeholder="User Name"
                  variant="standard"
                  className="userEditInput"
                  name="name"
                  onChange={handleEditChange}
                />
              </div>
              {/* Need to validate the nuber is from the UAE */}
              <div className="userEditItem">
                <label>Phone number</label>
                <TextField
                  id="userEditPhone"
                  type={"tel"}
                  placeholder="0501234567"
                  pattern="[0-9]{3}[0-9]{7}"
                  variant="standard"
                  className="userEditInput"
                  name="phoneNumber"
                  onChange={handleEditChange}
                />
              </div>

              <div className="userEditItem">
                <label>Shipping Address</label>
                {/* <TextField
                  id="userEditShippingAddress"
                  placeholder="City"
                  variant="standard"
                  className="userEditInput"
                  name="city"
                  onChange={handleShippingAddressChange}
                /> */}
                <select
                  id="userEditShippingAddress"
                  className="userEditInput"
                  name="city"
                  onChange={handleShippingAddressChange}
                >
                  <option value="Ajman">Ajman</option>
                  <option value="Abu Dhabi">Abu Dhabi</option>
                  <option value="Dubai">Dubai</option>
                  <option value="Sharjah">Sharjah</option>
                  <option value="Umm Al Quwain">Umm Al Quwain</option>
                  <option value="Fujairah">Fujairah</option>
                  <option value="Ras Al Khaimah">Ras Al Khaimah</option>
                </select>
                <TextField
                  id="userEditShippingAddress"
                  placeholder="Street"
                  variant="standard"
                  className="userEditInput"
                  name="street"
                  onChange={handleShippingAddressChange}
                />
              </div>
            </form>
            <button className="userEditSubmit" onClick={handleClick}>
              Update
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="user">
        <div className="userTitleContainer">
          <h1 className="userTitle">User</h1>
        </div>
        <h3> User not found </h3>
      </div>
    );
  }
}

import "./pendingOrders.css";

import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import { getOrdersByStatus } from "../../redux/apiCalls";

const columns = [
  { field: "_id", headerName: "ID", width: 90 },
  // { field: "user_id", headerName: "User ID", width: 150 },
  {
    field: "user_email",
    headerName: "Email",
    width: 150,
  },
  { field: "amount", headerName: "Amount", width: 150 },
  { field: "paymentOption", headerName: "Payment Option", width: 150 },
  {
    field: "shipping_address",
    headerName: "Shipping Address",
    width: 150,
    renderCell: (rowData) => {
      return (
        <div className="shipping_address">
          <p>{rowData.row.shipping_address.city}</p>
        </div>
      );
    },
  },
  { field: "status", headerName: "Status", width: 150 },
  {
    field: "createdAt",
    headerName: "Created At",
    width: 150,
    valueFormatter: (params) => {
      return new Date(params.value).toLocaleString("en-AE");
    },
  },
  {
    field: "updatedAt",
    headerName: "Updated At",
    width: 150,
    valueFormatter: (params) => {
      return new Date(params.value).toLocaleString("en-AE");
    },
  },
  {
    field: "actions",
    headerName: "Actions",
    sortable: false,
    width: 100,
    renderCell: (rowData) => {
      return (
        <div className="actions">
          <Link to={"/order/" + rowData.row._id}>
            <EditIcon className="pendingOrdersEdit" />
          </Link>
        </div>
      );
    },
  },
];

export default function PendingOrders() {
  // get data from api using axios
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await getOrdersByStatus("pending");
        if (res !== null) {
          setData(res);
          setLoading(false);
        } else {
          setLoading(true);
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, []);

  return (
    <div className="PendingOrders">
      <div className="pendingOrdersTop">
        <h2>Pending Orders</h2>
      </div>
      <DataGrid
        rows={loading ? [] : data}
        columns={columns}
        autoPageSize={true}
        checkboxSelection
        disableSelectionOnClick
        getRowId={(row) => row._id}
      />
    </div>
  );
}

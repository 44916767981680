/* eslint-disable no-unused-vars */
import "./newBrand.css";
import { useState } from "react";
import { TextField } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { createBrand } from "../../redux/apiCalls";

export default function NewBrand() {
  const [state, setState] = useState({});
  const [image, setImage] = useState(null);
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: { image: ["image/*"] },
      multiple: false,
      onDrop: (acceptedFiles, fileRejections) => {
        // check if file is rejected or not
        if (fileRejections.length > 0) {
          alert("Please upload an image file");
          return;
        } else {
          setImage(
            Object.assign(acceptedFiles[0], {
              preview: URL.createObjectURL(acceptedFiles[0]),
            })
          );
        }
      },
    });

  const handleChange = (e) => {
    e.target.value.replace(/^\s+/g, "");

    setState((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleFileRemove = (e, file) => {
    e.preventDefault();
    setImage(null);
  };

  const handleClick = async (e) => {
    e.preventDefault();

    // check if form is empty

    let empty = true;
    // loop through editState and check if any value is empty
    for (const [value] of Object.entries(state)) {
      if (value === "" || value === undefined || value === null) {
        empty = true;
        // break;
      } else {
        // empty = false;
      }
    }

    if (empty && image === null) {
      alert("Please Fill all fields and add at least one image and one tag");
      return;
    }

    // check if all fields are filled
    if (state.name && image !== null) {
      // create a new product
      const product = {
        name: state.name,
      };

      const form = new FormData();
      if (product.name) form.append("name", product.name);
      if (image !== null) {
        form.append("images", image);
      }

      try {
        let res = await createBrand(form);
        // check if response is not empty or undefined or null
        if (res !== undefined && res !== null && res !== "" && res !== false) {
          alert("Brand Created Successfully 😊");
          window.location.replace("/brands");
        } else {
          alert("Brand not created 😔");
        }
      } catch (err) {
        console.log("Brand not created 😔");
      }
    } else {
      alert("Please fill all fields 🙂");
    }
  };
  // console.log(state);
  // console.log(files);

  return (
    <div className="NewBrand">
      <form className="newBrandForm">
        <div className="newProductImageUpload">
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop some files here, or click to select files</p>
          </div>
          <aside>
            <h2>Files</h2>
            <ul className="fileList">
              {image && (
                <li key={image.path}>
                  <img src={image.preview} alt="product" />
                  <span>
                    {image.path} - {image.size} bytes
                  </span>
                  <button onClick={(e) => handleFileRemove(e, image)}>
                    REMOVE
                  </button>
                </li>
              )}
            </ul>
          </aside>
        </div>
        <div className="newBrandMiddle">
          {/* The english side */}
          <div className="newBrandMiddleLeft">
            <div className="newBrandItem">
              <label>Brand name:</label>
              <TextField
                id="newBrandName"
                placeholder="Phones"
                name="name"
                value={state.name}
                onChange={handleChange}
                margin="normal"
                variant="standard"
                className="newBrandInput"
              />
            </div>
          </div>
        </div>
        <div className="newBrandBottom">
          <input
            type="submit"
            value="Submit"
            className="newBrandSubmit"
            onClick={handleClick}
          />
        </div>
      </form>
    </div>
  );
}

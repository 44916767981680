import { useEffect, useState } from "react";
import { getProducts, getTodayAcceptedOrders, getTodayOrders } from "../../redux/apiCalls";
import "./featuredInfo.css";
import jsPDF from 'jspdf'
import 'jspdf-autotable'
export default function FeaturedInfo() {
  const [newOrders, setNewOrders] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  const [todaySales, setTodaySales] = useState(0);
  // const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await getTodayOrders();
        if (res !== null) {
          // get length of res
          let num = res.length;
          setNewOrders(num);
          console.log(num);
          // setLoading(false);
        } else {
          // setLoading(true);
        }
      } catch (err) {
        console.log(err);
      }
    }

    // fetch all products
    async function fetchAllProductsCount() {
      try {
        const res = await getProducts();
        if (res !== null) {
          // get length of res
          let num = res.length;
          setTotalProducts(num);
          console.log(num);
          // setLoading(false);
        } else {
          // setLoading(true);
        }
      } catch (err) {
        console.log(err);
      }
    }
    async function fetchLatestOrdersPrice() {
      try {
        const res = await getTodayAcceptedOrders();
        console.log(res);
        if (res !== null) {
          // get total price of res
          let total = 0;
          res.map((order) => {
            total += order.amount;
          });
          setTodaySales(total);

          // var doc = new jsPDF();
          // var col = ["_id", "amount", "paymentOption", "status", "user_id"];
          // var rows = [];
          // res.map((order) => {
          //   var temp = [order._id, order.amount, order.paymentOption, order.status, order.user_id];
          //   rows.push(temp);
          //   console.log(rows);
          // });
          // doc.autoTable(col, res)
          // doc.save("table.pdf")

          // setTodaySales(res);
          // setLoading(false);
        } else {
          // setLoading(true);
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
    fetchAllProductsCount();
    fetchLatestOrdersPrice();
  }, []);

  return (
    <div className="featuredInfo">
      <div className="featuredItem">
        <span className="featuredTitle">Today's Orders</span>
        <div className="featuredItemDetails">
          <span className="featuredValue">{newOrders}</span>
        </div>
      </div>
      <div className="featuredItem">
        <span className="featuredTitle">Total Products</span>
        <div className="featuredItemDetails">
          <span className="featuredValue">{totalProducts}</span>
        </div>
      </div>
      <div className="featuredItem">
        <span className="featuredTitle">Sales</span>
        <div className="featuredItemDetails">
          <span className="featuredValue">{todaySales}</span>
        </div>
      </div>
    </div>
  );
}

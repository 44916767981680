import { useEffect, useState } from "react";
import { getTnS, updateTnS } from "../../redux/apiCalls";
import "./termsAndConditions.css";

export default function TermsAndConditions() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await getTnS();
        if (res !== null) {
          setData(res);
          setLoading(false);
        } else {
          setData(null);
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, []);

  const [editData, setEditData] = useState({});
  // useEffect(() => {
  //   if (data.description) {
  //     setEditData(data.description);
  //   }
  // }, [data.description]);
  async function updateTnSfun() {
    try {
      const res = await updateTnS(editData);
      if (res !== undefined && res !== null && res !== "" && res !== false) {
        console.log(res);
        alert("Terms and Conditions updated successfully");
        window.location.reload();
      } else {
        alert("Failed to update Terms and Conditions");
      }
    } catch (err) {
      console.log(err);
    }
  }

  const handleEditChange = (e) => {
    setEditData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  return (
    <div className="TermsAndConditions">
      <div className="termsAndConditionsView">
        <h3 className="TermsAndConditionsTitle">Terms and Conditions</h3>
        <ul className="TermsAndConditionsList">
          {loading ? (
            <div className="loading">
              {/* {updateTnSfun({
                name: "terms",
                description:
                  "This is the Terms and Conditions page. You can edit this page by clicking the edit button below.",
              })} */}
              loading...
            </div>
          ) : (
            <span className="TermsAndConditionsListItem">
              {data.description_en}
              <br />
              <br />
              <br />
              {data.description_ar}
            </span>
          )}
        </ul>
      </div>
      <div className="termsAndConditionsEdit">
        <h3 className="TermsAndConditionsTitle">Edit Terms and Conditions</h3>

        <div className="TermsAndConditionsEditArea">
          <textarea
            className="TermsAndConditionsEditInput"
            placeholder="Enter your terms and conditions here"
            name="description_en"
            value={editData.description_en}
            onChange={handleEditChange}
          />
          <textarea
            className="TermsAndConditionsEditInput"
            placeholder="ادخل الشروط والاحكام هنا..."
            name="description_ar"
            value={editData.description_ar}
            onChange={handleEditChange}
          />
          <button
            className="TermsAndConditionsEditButton"
            disabled={loading}
            onClick={() => updateTnSfun()}
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
}

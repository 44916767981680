import { googleLogin, facebookLogin } from "../../redux/apiCalls";
import { useDispatch, useSelector } from "react-redux";
import "./login.css";

export default function Login() {
  const dispatch = useDispatch();
  const googleLoginBtn = (e) => {
    e.preventDefault();
    googleLogin(dispatch);
  };

  const facebookLoginBtn = (e) => {
    e.preventDefault();
    facebookLogin(dispatch);
  };

  var admin = false;
  const user = useSelector((state) => state.user.currentUser);
  if (user !== null) {
    admin = user.isAdmin;
  }

  return admin ? (
    (window.location.href = "/")
  ) : (
    <div className="login">
      <div className="wrapper">
        <h1 className="loginTitle">Login Using:</h1>
        <div className="center">
          <div className="loginButton google" onClick={googleLoginBtn}>
            <img src="./images/google.png" alt="" className="icon" />
            Google
          </div>
          <div className="loginButton facebook" onClick={facebookLoginBtn}>
            <img src="./images/facebook.png" alt="" className="icon" />
            Facebook
          </div>
        </div>
      </div>
    </div>
  );
}

import "./discount.css";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  deleteDiscount,
  getDiscountById,
  updateDiscount,
} from "../../redux/apiCalls";

export default function Discount() {
  const [state, setState] = useState({});

  const [available, setAvailable] = useState(false);

  useEffect(() => {
    // get product id from url
    const DiscountId = window.location.pathname.split("/").pop();
    async function fetchData() {
      try {
        let res = await getDiscountById(DiscountId);
        console.log(res);
        if (res !== false && res !== null) {
          setState(res);
          setAvailable(true);
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, []);

  // ===========================================
  // ===========================================
  // ===========================================

  // EDIT DISCOUNT
  const [editState, setEditState] = useState({});

  const handleEditChange = (e) => {
    setEditState((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleClick = async (e) => {
    e.preventDefault();
    const discount = {
      name: editState.name,
      description: editState.description,
      discount_percentage: editState.discount_percentage,
      active: editState.active,
      start_date: editState.start_date,
      end_date: editState.end_date,
    };
    try {
      let res = await updateDiscount(state._id, discount);
      if (res !== null && res !== undefined && res !== false) {
        console.log(res);
        alert("Discount created successfully");
      } else {
        alert("Discount creation failed");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteDiscount = async (e) => {
    e.preventDefault();
    try {
      console.log(state._id);
      console.log(state);
      let res = await deleteDiscount(state._id);
      if (res !== undefined && res !== null && res !== "" && res !== false) {
        alert("Discount Deleted Successfully! 🙁");
        handleClose();
        window.location.replace("/discounts");
      } else {
        alert("Discount not deleted 😔");
      }
    } catch (err) {
      console.log(err);
    }
  };

  if (available) {
    return (
      <div className="Discount">
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to delete this discount?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
            <Button onClick={handleDeleteDiscount} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <div className="DiscountTitleContainer">
          <h1 className="DiscountTitle">Discount</h1>
          <button
            className="discountDeleteButton"
            onClick={() => handleClickOpen()}
          >
            Delete
          </button>
        </div>
        {/* 
      =================================================================
      Display the discount's current data 
      =================================================================
      */}
        <div className="discountShow">
          <div className="discountShowTop">
            <span className="discountShowName">{state.name}</span>
            <span className="discountShowId">{state._id}</span>
          </div>
          <div className="discountShowMiddle">
            <span className="discountShowDescription">
              Discount Description: <b>{state.description}</b>
            </span>
            <span className="discountShowPercentage">
              Discount Percentage: <b>{state.discount_percentage}%</b>
            </span>
            <span className="discountShowStartDate">
              Start Date:{" "}
              <b>{new Date(state.start_date).toLocaleString("en-AE")}</b>
            </span>
            <span className="discountShowEndDate">
              End Date:{" "}
              <b>{new Date(state.end_date).toLocaleString("en-AE")}</b>
            </span>
            <span className="discountShowActive">
              Active: <b>{state.active ? "Yes" : "No"}</b>
            </span>
          </div>
          <div className="discountShowBottom">
            <span className="discountShowInfo">
              Created At:{" "}
              <b>{new Date(state.createdAt).toLocaleString("en-AE")}</b>
            </span>
            <span className="doscountShowIndo">
              Updated At:{" "}
              <b>{new Date(state.updatedAt).toLocaleString("en-AE")}</b>
            </span>
          </div>
        </div>
        {/* 
      =================================================================
      Edit the discount's data 
      =================================================================
      */}
        <div className="DiscountTitleContainer">
          <h1 className="DiscountTitle">Edit</h1>
        </div>
        <div className="discountEdit">
          <form className="discountForm">
            <div className="discountEditMiddle">
              <div className="discountEditItem">
                <label>Discount Name:</label>
                <TextField
                  id="discountEditName"
                  placeholder="Discount name"
                  name="name"
                  value={editState.name}
                  onChange={handleEditChange}
                  margin="normal"
                  variant="standard"
                  className="discountEditInput"
                />
              </div>
              <div className="discountEditItem">
                <label>Discount Description:</label>
                <TextField
                  id="discountEditDescription"
                  placeholder="Discount Description"
                  name="description"
                  value={editState.description}
                  onChange={handleEditChange}
                  margin="normal"
                  variant="standard"
                  className="discountEditInput"
                />
              </div>
              <div className="discountEditItem">
                <label>Discount Percentage:</label>
                <TextField
                  id="discountEditPercentage"
                  placeholder="Discount Percentage"
                  name="discount_percentage"
                  type={"number"}
                  value={editState.discount_percentage}
                  onChange={handleEditChange}
                  margin="normal"
                  variant="standard"
                  className="discountEditInput"
                />
              </div>
              <div className="discountEditItem">
                <label>Active:</label>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  name="active"
                  value={editState.active}
                  onChange={handleEditChange}
                  className="discountEditInput"
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </div>
              <div className="discountEditItem">
                <label>Discount Start Date:</label>
                <TextField
                  id="discountEditStartDate"
                  placeholder="Discount Start Date"
                  name="start_date"
                  type={"datetime-local"}
                  value={editState.start_date}
                  onChange={handleEditChange}
                  margin="normal"
                  variant="standard"
                  className="discountEditInput"
                />
              </div>
              <div className="discountEditItem">
                <label>Discount End Date:</label>
                <TextField
                  id="discountEditEndDate"
                  placeholder="Discount End Date"
                  name="end_date"
                  type={"datetime-local"}
                  value={editState.end_date}
                  onChange={handleEditChange}
                  margin="normal"
                  variant="standard"
                  className="discountEditInput"
                />
              </div>
            </div>
            <div className="discountEditBottom">
              <input
                type="submit"
                value="Update"
                className="discountEditSubmit"
                onClick={handleClick}
              />
            </div>
          </form>
        </div>
      </div>
    );
  } else {
    return (
      <div className="Discount">
        <div className="DiscountTitleContainer">
          <h1 className="DiscountTitle">Discount</h1>
        </div>
        <h3>Discount not found</h3>
      </div>
    );
  }
}

import "./blockedUserList.css";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import { Input } from "@mui/material";
import {
  getBlockedUsers,
  searchBlockedUsersByEmail,
} from "../../redux/apiCalls";

const columns = [
  { field: "_id", headerName: "ID", width: 90 },
  {
    field: "name",
    headerName: "Name",
    width: 150,
  },
  {
    field: "email",
    headerName: "Email",
    width: 150,
  },
  {
    field: "provider",
    headerName: "Provider",
    width: 100,
  },
  {
    field: "providerId",
    headerName: "Provider Id",
    sortable: false,
    width: 160,
  },
  {
    field: "isVerified",
    headerName: "Is Verified",
    width: 100,
  },
  {
    field: "isAdmin",
    headerName: "Is Admin",
    width: 100,
  },
  {
    field: "createdAt",
    headerName: "Created At",
    width: 100,
    valueFormatter: (params) => {
      return new Date(params.value).toLocaleString("en-AE");
    },
  },
  {
    field: "updatedAt",
    headerName: "Updated At",
    sortable: false,
    width: 100,
    valueFormatter: (params) => {
      return new Date(params.value).toLocaleString("en-AE");
    },
  },
  {
    field: "actions",
    headerName: "Actions",
    sortable: false,
    width: 100,
    renderCell: (rowData) => {
      return (
        <div className="actions">
          <Link to={"/user/" + rowData.row._id}>
            <EditIcon className="blockedUserListEdit" />
          </Link>
        </div>
      );
    },
  },
];

export default function BlockedUserList() {
  // get data from api using axios
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await getBlockedUsers();
        if (res !== null) {
          setData(res);
          setLoading(false);
        } else {
          setLoading(true);
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, []);

  async function handleSearch(value) {
    // if value is empty, trigger the useEffect to get all products
    if (value === "") {
      const res = await getBlockedUsers();
      if (res !== null) {
        setData(res);
        setLoading(false);
      } else {
        setLoading(true);
      }
    } else {
      const res = await searchBlockedUsersByEmail(value);
      if (res !== null) {
        setData(res);
        setLoading(false);
      } else {
        setLoading(true);
      }
    }
  }

  return (
    <div className="BlockedUserList">
      <div className="blockedUserListTop">
        <h2>Blocked Users</h2>
        <Input
          type="text"
          placeholder="Search"
          className="ListSearch"
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>
      <DataGrid
        rows={loading ? [] : data}
        columns={columns}
        checkboxSelection
        disableSelectionOnClick
        getRowId={(row) => row._id}
        // autoPageSize={true}
        rowsPerPageOptions={[10, 20, 50, 100]}
      />
    </div>
  );
}

/* eslint-disable no-unused-vars */
import "./newProduct.css";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Input from "@mui/material/Input";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  createProduct,
  getBrands,
  getCategories,
  getDiscounts,
} from "../../redux/apiCalls";

export default function NewProduct() {
  const [state, setState] = useState({});
  const [files, setFiles] = useState([]);
  const [tags, setTags] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: { image: ["image/*"] },
      onDrop: (acceptedFiles, fileRejections) => {
        // check if file is rejected or not

        if (fileRejections.length > 0) {
          alert("Please upload an image file");
          return;
        } else {
          const mappedFiles = acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          );
          setFiles((files) => [...files, ...mappedFiles]);
        }
      },
    });

  const handleChange = (e) => {
    e.target.value.replace(/^\s+/g, "");

    setState((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleTags = (e) => {
    // trim spaces from the start
    const trimmed = e.target.value.replace(/^\s+/g, "");
    // check if text is empty
    if (trimmed === "") {
      setTags([]);
    } else {
      // split by comma
      setTags(e.target.value.split(","));
    }
  };

  const handleFileRemove = (e, file) => {
    e.preventDefault();
    setFiles(files.filter((item) => item !== file));
  };

  const handleClick = async (e) => {
    e.preventDefault();

    // check if form is empty

    let empty = true;
    // loop through editState and check if any value is empty
    for (const [value] of Object.entries(state)) {
      if (value === "" || value === undefined || value === null) {
        empty = true;
        // break;
      } else {
        // empty = false;
      }
    }

    if (empty && files.length === 0 && tags.length === 0) {
      alert("Please Fill all fields and add at least one image and one tag");
      return;
    }

    // check if all fields are filled
    if (
      state.name_en &&
      state.name_ar &&
      state.description_en &&
      state.description_ar &&
      state.short_description_en &&
      state.short_description_ar &&
      state.sku &&
      state.category &&
      state.brand &&
      state.price &&
      tags.length !== 0 &&
      state.quantity &&
      files.length > 0
    ) {
      // create a new product
      const product = {
        name_en: state.name_en,
        name_ar: state.name_ar,
        description_en: state.description_en,
        description_ar: state.description_ar,
        short_description_en: state.short_description_en,
        short_description_ar: state.short_description_ar,
        sku: state.sku,
        code: state.code ? state.code : null,
        category: state.category,
        brand: state.brand,
        discountID: state.discount,
        price: state.price,
        quantity: state.quantity,
        tags: tags,
      };

      const form = new FormData();
      if (product.name_en) form.append("name_en", product.name_en);
      if (product.name_ar) form.append("name_ar", product.name_ar);
      if (product.description_en)
        form.append("description_en", product.description_en);
      if (product.description_ar)
        form.append("description_ar", product.description_ar);
      if (product.short_description_en)
        form.append("short_description_en", product.short_description_en);
      if (product.short_description_ar)
        form.append("short_description_ar", product.short_description_ar);
      if (product.sku) form.append("sku", product.sku);
      if (product.code) form.append("code", product.code);
      if (product.category) form.append("category", product.category);
      if (product.brand) form.append("brand", product.brand);
      if (product.discountID) form.append("discountID", product.discountID);
      if (product.price) form.append("price", product.price);
      if (product.quantity) form.append("quantity", product.quantity);
      // if (product.tags) form.append("tags", product.tags);
      // add tags to form only if tags array is not empty
      if (
        tags.length > 0 &&
        tags !== null &&
        tags !== undefined &&
        product.tags.length > 0
      ) {
        product.tags.map((tag) => form.append("tags[]", tag));
      }
      if (files.length > 0) {
        files.forEach((file) => {
          form.append("images", file);
        });
      }

      try {
        let res = await createProduct(form);
        if (res !== undefined && res !== null && res !== "" && res !== false) {
          alert("Product Created Successfully 😊");
          window.location.replace("/products");
        } else {
          alert("Product not created 😔");
        }
      } catch (err) {
        console.log("Product not created 😔");
      }
    } else {
      alert("Please fill all fields 🙂");
    }
  };

  useEffect(() => {
    async function fetchCategories() {
      try {
        const res = await getCategories();
        if (res !== null) {
          setCategories(res);
        }
      } catch (err) {
        console.log(err);
      }
    }
    async function fetchBrands() {
      try {
        const res = await getBrands();
        if (res !== null) {
          setBrands(res);
        }
      } catch (err) {
        console.log(err);
      }
    }

    async function fetchDiscounts() {
      try {
        const res = await getDiscounts();
        if (res !== null) {
          setDiscounts(res);
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchCategories();
    fetchBrands();
    fetchDiscounts();
  }, []);

  // console.log(state);
  // console.log(tags);
  // console.log(files);
  return (
    <div className="NewProduct">
      <form className="newProductForm">
        <div className="newProductTop">
          <div className="newProductImageUpload">
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            <aside>
              <h2>Files</h2>
              <ul className="fileList">
                {files.map((file) => (
                  <li key={file.path}>
                    <img src={file.preview} alt="product" />
                    <span>
                      {file.path} - {file.size} bytes
                    </span>
                    <button onClick={(e) => handleFileRemove(e, file)}>
                      REMOVE
                    </button>
                  </li>
                ))}
              </ul>
            </aside>
          </div>
        </div>
        <div className="newProductMiddle">
          {/* The english side */}
          <div className="newProductMiddleLeft">
            <div className="newProductItem">
              <label>Product name:</label>
              <TextField
                id="newProductName"
                placeholder="iPhone 13"
                name="name_en"
                value={state.name_en}
                onChange={handleChange}
                margin="normal"
                variant="standard"
                className="newProductInput"
              />
            </div>
            <div className="newProductItem">
              <label>Product description:</label>
              <TextField
                id="newProductDescription"
                placeholder="new iPhone 13 128GB storage Green"
                name="description_en"
                value={state.description_en}
                onChange={handleChange}
                multiline
                margin="normal"
                variant="standard"
                className="newProductInput"
              />
            </div>
            <div className="newProductItem">
              <label>Product Short Description:</label>
              <TextField
                id="newProductShortDescription"
                placeholder="new iPhone 13"
                name="short_description_en"
                value={state.short_description_en}
                onChange={handleChange}
                multiline
                margin="normal"
                variant="standard"
                className="newProductInput"
              />
            </div>
          </div>
          {/* The arabic side */}
          <div className="newProductMiddleRight">
            <div className="newProductItem">
              <label>اسم المنتج:</label>
              <TextField
                id="newProductName"
                placeholder="ايفون 13"
                name="name_ar"
                value={state.name_ar}
                onChange={handleChange}
                margin="normal"
                variant="standard"
                className="newProductInput"
              />
            </div>
            <div className="newProductItem">
              <label>وصف المنتج:</label>
              <TextField
                id="newProductDescription"
                placeholder="جهاز ايفون 13 جديد مساحة تخزين 128 غيغا أخضر"
                name="description_ar"
                value={state.description_ar}
                onChange={handleChange}
                multiline
                margin="normal"
                variant="standard"
                className="newProductInput"
              />
            </div>
            <div className="newProductItem">
              <label>وصف مختصر:</label>
              <TextField
                id="newProductShortDescription"
                placeholder="ايفون 13 جديد"
                name="short_description_ar"
                value={state.short_description_ar}
                onChange={handleChange}
                multiline
                margin="normal"
                variant="standard"
                className="newProductInput"
              />
            </div>
          </div>
        </div>
        <div className="newProductBottom">
          <div className="newProductItem">
            <label>SKU:</label>
            <Input
              id="newProductSKU"
              placeholder="SKU"
              name="sku"
              value={state.sku}
              onChange={handleChange}
              margin="normal"
              variant="standard"
              className="newProductInput"
            />
          </div>
          <div className="productEditItem">
            <label className="newProductCode">CODE:</label>
            <Input
              id="newProductCode"
              placeholder="XXXXX"
              name="code"
              value={state.code}
              onChange={handleChange}
              className="newProductCode"
            />
          </div>
          <div className="newProductRow">
            <div className="newProductItem">
              <label>Category:</label>
              <select
                id="newProductCategory"
                className="newProductInput"
                name="category"
                // onClick={() => fetchCategories()}
                onChange={handleChange}
              >
                <option value=""></option>
                {categories.map((category) => (
                  <option key={category._id} value={category._id}>
                    {category.name_en}
                  </option>
                ))}
              </select>
            </div>
            <div className="newProductItem">
              <label>Brands:</label>
              <select
                id="newProductBrands"
                className="newProductInput"
                name="brand"
                // onClick={() => fetchBrands()}
                onChange={handleChange}
              >
                <option value=""></option>
                {brands.map((brand) => (
                  <option key={brand._id} value={brand._id}>
                    {brand.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="newProductItem">
              <label>Discount:</label>
              <select
                id="newProductDiscount"
                className="newProductInput"
                name="discount"
                // onClick={() => fetchDiscounts()}
                onChange={handleChange}
              >
                <option value=""></option>
                {discounts.map((discount) => (
                  <option key={discount._id} value={discount._id}>
                    {discount.name}
                    {" - "}
                    {discount.discount_percentage}%
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="newProductRow">
            <div className="newProductItem">
              <label className="newProductPrice">Price:</label>
              <Input
                id="productEditPrice"
                placeholder="2999.99"
                name="price"
                value={state.price}
                onChange={handleChange}
                type="number"
                startAdornment={
                  <InputAdornment position="start">AED</InputAdornment>
                }
                className="productEditPrice"
              />
            </div>
            <div className="newProductItem">
              <label>Tags:</label>
              <TextField
                id="productEditTags"
                placeholder="iphone,phones,mobile,new"
                name="tags"
                value={state.tags}
                onChange={handleTags}
                multiline
                margin="normal"
                variant="standard"
                className="productEditInput"
              />
            </div>
          </div>
          <div className="productEditItem">
            <label className="productEditQuantity">Quantity:</label>
            <Input
              id="productEditQuantity"
              placeholder="11"
              name="quantity"
              value={state.quantity}
              onChange={handleChange}
              type="number"
              className="productEditQuantity"
            />
          </div>
          <input
            type="submit"
            value="Submit"
            onClick={handleClick}
            className="productEditSubmit"
          />
        </div>
      </form>
    </div>
  );
}

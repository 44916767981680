import "./latestUsers.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useEffect, useState } from "react";
import { getLatestUsers } from "../../redux/apiCalls";
import { useNavigate } from "react-router-dom";

export default function LatestUsers() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await getLatestUsers();
        if (res !== null) {
          setUsers(res);
          setLoading(false);
        } else {
          setLoading(true);
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, []);

  const DisplayUser = (id) => {
    navigate(`/user/${id}`);
  };

  return (
    <div className="latestUsers">
      <span className="latestUsersTitle">New Users</span>
      <ul className="latestUsersList">
        {loading ? (
          <tr>
            <div className="loading">Loading...</div>
          </tr>
        ) : (
          users.map((user) => (
            <li className="latestUsersListItem" key={user._id}>
              <div className="latestUsersUser">
                <span className="latestUsersUSername">{user.name}</span>
                <span className="latestUsersUserEmail">{user.email}</span>
              </div>
              <button
                className="latestUsersButton"
                onClick={() => DisplayUser(user._id)}
              >
                <VisibilityIcon className="latestUSersIcon" />
                Display
              </button>
            </li>
          ))
        )}
      </ul>
    </div>
  );
}

import "./topbar.css";
//import material-ui components
import LogoutIcon from "@mui/icons-material/Logout";

import { userLogout } from "../../redux/apiCalls";
import { useDispatch, useSelector } from "react-redux";

export default function Topbar() {
  // fetch username from redux store
  const username = useSelector((state) => state.user.currentUser.name);

  // var username =
  const dispatch = useDispatch();
  const logoutBtn = (e) => {
    e.preventDefault();
    userLogout(dispatch);
  };

  return (
    <div className="topbar">
      <div className="topbarWrapper">
        <div className="topbar-left">
          <span className="logo">PRESTIGE STORE</span>
        </div>
        <div className="topbar-right">
          <a href="/">
            <p className="userName">{username}</p>
          </a>
          <div className="logout-icon" onClick={logoutBtn}>
            <LogoutIcon />
            {/* create hover text */}
            <span className="tooltip">LOGOUT</span>
          </div>
        </div>
      </div>
    </div>
  );
}

import "./sidebar.css";
import DashboardIcon from "@mui/icons-material/Dashboard";
import StorefrontIcon from "@mui/icons-material/Storefront";
import CategoryIcon from "@mui/icons-material/Category";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import GroupIcon from "@mui/icons-material/Group";
import DiscountIcon from "@mui/icons-material/Discount";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import BlockIcon from "@mui/icons-material/Block";
import BusinessIcon from "@mui/icons-material/Business";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import InfoIcon from "@mui/icons-material/Info";
import SettingsIcon from '@mui/icons-material/Settings';
import { Link, useLocation } from "react-router-dom";
import { ViewCarousel } from "@mui/icons-material";

const SidebarItemSubURLs = {
  "/": ["/"],
  "/products": ["/products", "/newProduct", "/product"],
  "/categories": ["/categories", "/newCategory", "/category"],
  "/brands": ["/brands", "/newBrand", "/brand"],
  "/featuredProducts": ["/newFeaturedProduct", "/featuredProduct"],
  "/orders": ["/orders", "/order"],
  "/approvedOrders": ["/approvedOrders"],
  "/pendingOrders": ["/pendingOrders"],
  "/declinedOrders": ["/declinedOrders"],
  "/discounts": ["/discounts", "/newDiscount", "/discount"],
  "/users": ["/users", "/user"],
  "/admins": ["/admins"],
  "/blockedUsers": ["/blockedUsers"],
  "/termsAndConditions": ["/termsAndConditions"],
  "/privacyPolicy": ["/privacyPolicy"],
  "/returnRefund": ["/returnRefund"],
  "/aboutUs": ["/aboutUs"],
  "/configs": ["/configs"],
};
export default function Sidebar() {
  const location = useLocation().pathname.split("/")[1];
  // console.log(location);

  function subId(sub) {
    let URI = `/${location}`;
    // console.log(URI);
    return SidebarItemSubURLs[sub].includes(URI);
  }
  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Dashboard</h3>
          <ul className="sidebarList">
            <Link to="/" style={{ color: "inherit", textDecoration: "none" }}>
              <li className={subId("/") ? "sidebarItem active" : "sidebarItem"}>
                <DashboardIcon className="sidebarIcon" />
                <p>Home</p>
              </li>
            </Link>
          </ul>
        </div>

        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Products</h3>
          <ul className="sidebarList">
            <Link
              to="/products"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <li
                className={
                  subId("/products") ? "sidebarItem active" : "sidebarItem"
                }
              >
                <StorefrontIcon className="sidebarIcon" />
                <p>Products</p>
              </li>
            </Link>
          </ul>
        </div>

        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Categories</h3>
          <ul className="sidebarList">
            <Link
              to="/categories"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <li
                className={
                  subId("/categories") ? "sidebarItem active" : "sidebarItem"
                }
              >
                <CategoryIcon className="sidebarIcon" />
                <p>Categories</p>
              </li>
            </Link>
          </ul>
        </div>

        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Brands</h3>
          <ul className="sidebarList">
            <Link
              to="/brands"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <li
                className={
                  subId("/brands") ? "sidebarItem active" : "sidebarItem"
                }
              >
                <BusinessIcon className="sidebarIcon" />
                <p>Brands</p>
              </li>
            </Link>
          </ul>
        </div>

        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Featured Products</h3>
          <ul className="sidebarList">
            <Link
              to="/featuredProducts"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <li
                className={
                  subId("/featuredProducts")
                    ? "sidebarItem active"
                    : "sidebarItem"
                }
              >
                <ViewCarousel className="sidebarIcon" />
                <p>Featured Products</p>
              </li>
            </Link>
          </ul>
        </div>

        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Orders</h3>
          <ul className="sidebarList">
            <Link
              to="/orders"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <li
                className={
                  subId("/orders") ? "sidebarItem active" : "sidebarItem"
                }
              >
                <ShoppingCartIcon className="sidebarIcon" />
                <p>Orders</p>
              </li>
            </Link>
            <Link
              to="/approvedOrders"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <li
                className={
                  subId("/approvedOrders")
                    ? "sidebarItem active"
                    : "sidebarItem"
                }
              >
                <ThumbUpOffAltIcon className="sidebarIcon" />
                <p>Approved Orders</p>
              </li>
            </Link>
            <Link
              to="/pendingOrders"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <li
                className={
                  subId("/pendingOrders") ? "sidebarItem active" : "sidebarItem"
                }
              >
                <PauseCircleOutlineIcon className="sidebarIcon" />
                <p>Pending Orders</p>
              </li>
            </Link>
            <Link
              to="/declinedOrders"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <li
                className={
                  subId("/declinedOrders")
                    ? "sidebarItem active"
                    : "sidebarItem"
                }
              >
                <ThumbDownOffAltIcon className="sidebarIcon" />
                <p>Declined Orders</p>
              </li>
            </Link>
          </ul>
        </div>

        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Discounts</h3>
          <ul className="sidebarList">
            <Link
              to="/discounts"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <li
                className={
                  subId("/discounts") ? "sidebarItem active" : "sidebarItem"
                }
              >
                <DiscountIcon className="sidebarIcon" />
                <p>Discounts</p>
              </li>
            </Link>
          </ul>
        </div>

        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Users</h3>
          <ul className="sidebarList">
            <Link
              to="/users"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <li
                className={
                  subId("/users") ? "sidebarItem active" : "sidebarItem"
                }
              >
                <GroupIcon className="sidebarIcon" />
                <p>Users</p>
              </li>
            </Link>
            <Link
              to="/admins"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <li
                className={
                  subId("/admins") ? "sidebarItem active" : "sidebarItem"
                }
              >
                <AdminPanelSettingsIcon className="sidebarIcon" />
                <p>Admins</p>
              </li>
            </Link>
            <Link
              to="/blockedUsers"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <li
                className={
                  subId("/blockedUsers") ? "sidebarItem active" : "sidebarItem"
                }
              >
                <BlockIcon className="sidebarIcon" />
                <p>Blocked Users</p>
              </li>
            </Link>
          </ul>
        </div>

        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Others</h3>
          <ul className="sidebarList">
            <Link
              to="/termsAndConditions"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <li
                className={
                  subId("/termsAndConditions")
                    ? "sidebarItem active"
                    : "sidebarItem"
                }
              >
                <GroupIcon className="sidebarIcon" />
                <p>Terms And Conditions</p>
              </li>
            </Link>
            <Link
              to="/privacyPolicy"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <li
                className={
                  subId("/privacyPolicy") ? "sidebarItem active" : "sidebarItem"
                }
              >
                <AdminPanelSettingsIcon className="sidebarIcon" />
                <p>Privacy Policy</p>
              </li>
            </Link>
            <Link
              to="/returnRefund"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <li
                className={
                  subId("/returnRefund") ? "sidebarItem active" : "sidebarItem"
                }
              >
                <AttachMoneyIcon className="sidebarIcon" />
                <p>Return And Refund</p>
              </li>
            </Link>
            <Link
              to="/aboutUs"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <li
                className={
                  subId("/aboutUs") ? "sidebarItem active" : "sidebarItem"
                }
              >
                <InfoIcon className="sidebarIcon" />
                <p>About Us</p>
              </li>
            </Link>
            <Link
              to="/configs"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <li
                className={
                  subId("/configs") ? "sidebarItem active" : "sidebarItem"
                }
              >
                <SettingsIcon className="sidebarIcon" />
                <p>Configs</p>
              </li>
            </Link>
          </ul>
        </div>
      </div>
    </div>
  );
}

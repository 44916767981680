/* eslint-disable no-unused-vars */
import "./featuredProduct.css";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  deleteFeaturedProduct,
  deleteImageById,
  getFeaturedProductById,
  updateFeaturedProduct,
} from "../../redux/apiCalls";

export default function FeaturedProduct() {
  const [state, setState] = useState({});

  const [showImage, setShowImage] = useState();
  const [available, setAvailable] = useState(false);

  useEffect(() => {
    // get brand id from url
    const featuredProductID = window.location.pathname.split("/").pop();
    async function fetchData() {
      try {
        let res = await getFeaturedProductById(featuredProductID);
        console.log(res);
        if (res !== false && res !== null) {
          setState(res);
          setAvailable(true);
        } else {
          setAvailable(false);
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, []);

  const handleDeleteImage = (e, id) => {
    e.preventDefault();
    // display confirmation dialog
    const confirm = window.confirm(
      "Are you sure you want to delete this image?"
    );
    if (confirm) {
      try {
        deleteImageById(id);
        // setImages(images.filter((image) => image.id !== id));
        setState({
          ...state,
          image: null,
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    try {
      async function getImages() {
        if (state.image) {
          // check if image is already in the array
          const temp_image = state.image;
          setShowImage({ url: temp_image.url, id: state.image.key });
        }
      }
      getImages();
    } catch (err) {
      console.log(err);
    }
  }, [state.image]);

  // const handleChange = (e) => {
  //   setState((prevState) => {
  //     return {
  //       ...prevState,
  //       [e.target.name]: e.target.value,
  //     };
  //   });
  // };

  // ===========================================
  // ===========================================
  // ===========================================

  // EDIT featured product
  const [editState, setEditState] = useState({});
  const [image, setImage] = useState(null);
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: { image: ["image/*"] },
      multiple: false,
      onDrop: (acceptedFiles, fileRejections) => {
        // check if there are any rejected files
        if (fileRejections.length > 0) {
          alert("Please upload an image file");
        } else {
          setImage(
            Object.assign(acceptedFiles[0], {
              preview: URL.createObjectURL(acceptedFiles[0]),
            })
          );
        }
      },
    });

  const handleEditChange = (e) => {
    e.target.value.replace(/^\s+/g, "");

    setEditState((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleFileRemove = (e, file) => {
    e.preventDefault();
    setImage(null);
  };

  const handleClick = async (e) => {
    e.preventDefault();

    // check if form is empty

    let empty = true;
    // loop through editState and check if any value is empty
    for (const [value] of Object.entries(editState)) {
      if (value === "" || value === undefined || value === null) {
        // empty = true;
        // break;
      } else {
        empty = false;
      }
    }

    if (empty && image === null) {
      alert("Please change at least one field");
      return;
    }

    const featuredProduct = {
      product_id: editState.product_id,
      title_en: editState.title_en,
      title_ar: editState.title_ar,
      description_en: editState.description_en,
      description_ar: editState.description_ar,
    };

    const form = new FormData();
    form.append("id", state._id);
    if (featuredProduct.product_id)
      form.append("product_id", featuredProduct.product_id);
    if (featuredProduct.title_en)
      form.append("title_en", featuredProduct.title_en);
    if (featuredProduct.title_ar)
      form.append("title_ar", featuredProduct.title_ar);
    if (featuredProduct.description_en)
      form.append("description_en", featuredProduct.description_en);
    if (featuredProduct.description_ar)
      form.append("description_ar", featuredProduct.description_ar);
    if (image) form.append("images", image);

    try {
      let res = await updateFeaturedProduct(state._id, form);
      if (res !== undefined && res !== null && res !== "" && res !== false) {
        alert("Featured Product updated successfully 😊");
      } else {
        alert("Featured Product update failed 😢");
      }
    } catch (err) {
      console.log(err);
    }
  };

  // // load image
  // useEffect(() => {
  //   if (state.image) {
  //     // setEditImage(state.image);
  //   }
  // }, [state]);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteBrand = async () => {
    try {
      console.log(state._id);
      console.log(state);
      let res = await deleteFeaturedProduct(state._id);
      if (res !== undefined && res !== null && res !== "" && res !== false) {
        alert("Featured Product Deleted Successfully! 😊");
        handleClose();
        window.location.replace("/featuredProducts");
      } else {
        alert("Featured Product not deleted 😔");
      }
    } catch (err) {
      console.log(err);
    }
  };
  if (available) {
    return (
      <div className="FeaturedProduct">
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to delete this Featured Product?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
            <Button onClick={handleDeleteBrand} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <div className="featuredProductTitleContainer">
          <h1 className="featuredProductTitle">Featured Product</h1>
          <button
            className="featuredProductDeleteButton"
            onClick={() => handleClickOpen()}
          >
            Delete
          </button>
        </div>
        {/* 
=================================================================
Display the Brand's current data 
=================================================================
*/}
        <div className="featuredProductShow">
          <div className="featuredProductShowTop">
            <div className="featuredProductShowTopImage">
              {showImage && (
                <div className="featuredProductShowImageContainer">
                  <img
                    className="featuredProductShowImage"
                    src={showImage.url}
                    alt="featuredProduct"
                  />
                  <button onClick={(e) => handleDeleteImage(e, showImage.id)}>
                    REMOVE
                  </button>
                </div>
              )}
            </div>
            <span className="featuredProductShowId">{state._id}</span>
          </div>
          <span className="featuredProductShowProductId">
            Product Id: <b>{state.product_id}</b>
          </span>
          <div className="featuredProductShowMiddle">
            <div className="featuredProductShowMiddleLeft">
              <span className="featuredProductShowTitle">
                Title: <b>{state.title_en}</b>
              </span>
              <span className="featuredProductShowDescriptionEn">
                Description: <b>{state.description_en}</b>
              </span>
            </div>
            <div className="featuredProductShowMiddleRight">
              <span className="featuredProductShowTitle">
                العنوان: <b>{state.title_ar}</b>
              </span>
              <span className="featuredProductShowDescriptionAr">
                الوصف: <b>{state.description_ar}</b>
              </span>
            </div>
          </div>
          <div className="featuredProductShowBottom">
            <span className="featuredProductShowInfo">
              Created At:{" "}
              <b>{new Date(state.createdAt).toLocaleString("en-AE")}</b>
            </span>
            <span className="featuredProductShowInfo">
              Updated At:{" "}
              <b>{new Date(state.updatedAt).toLocaleString("en-AE")}</b>
            </span>
          </div>
        </div>
        {/* 
=================================================================
Edit the featured Product's data 
=================================================================
*/}
        <div className="featuredProductTitleContainer">
          <h1 className="featuredProductTitle">Edit</h1>
        </div>
        <div className="featuredProductEdit">
          <form className="featuredProductForm">
            <div className="featuredProductEditTop">
              <div className="featuredProductEditImageUpload">
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  <p>Drag 'n' drop the image here, or click to select one</p>
                </div>
                <aside>
                  <h2>image</h2>
                  <ul className="fileList">
                    {image && (
                      <li key={image}>
                        <img src={image.preview} alt="featuredProduct" />
                        <span>
                          {image.path} - {image.size} bytes
                        </span>
                        <button onClick={(e) => handleFileRemove(e, image)}>
                          REMOVE
                        </button>
                      </li>
                    )}
                  </ul>
                </aside>
              </div>
            </div>
            <div className="featuredProductEditMiddle">
              <div className="featuredProductEditMiddleLeft">
                <div className="featuredProductEditItem">
                  <label>Title:</label>
                  <TextField
                    id="featuredProductEditTitleEn"
                    placeholder="Title"
                    name="title_en"
                    value={editState.title_en}
                    onChange={handleEditChange}
                    margin="normal"
                    variant="standard"
                    className="featuredProductEditInput"
                  />
                </div>
                <div className="featuredProductEditItem">
                  <label>Description:</label>
                  <TextField
                    id="featuredProductEditDescriptionEn"
                    placeholder="Description"
                    name="description_en"
                    value={editState.description_en}
                    onChange={handleEditChange}
                    margin="normal"
                    variant="standard"
                    className="featuredProductEditInput"
                  />
                </div>
              </div>
              <div className="featuredProductEditMiddleRight">
                <div className="featuredProductEditItem">
                  <label>العنوان:</label>
                  <TextField
                    id="featuredProductEditTitleAr"
                    placeholder="العنوان"
                    name="title_ar"
                    value={editState.title_ar}
                    onChange={handleEditChange}
                    margin="normal"
                    variant="standard"
                    className="featuredProductEditInput"
                  />
                </div>
                <div className="featuredProductEditItem">
                  <label>الوصف:</label>
                  <TextField
                    id="featuredProductEditDescriptionAr"
                    placeholder="الوصف"
                    name="description_ar"
                    value={editState.description_ar}
                    onChange={handleEditChange}
                    margin="normal"
                    variant="standard"
                    className="featuredProductEditInput"
                  />
                </div>
              </div>
            </div>
            <div className="featuredProductEditBottom">
              <input
                type="submit"
                value="Update"
                className="featuredProductEditSubmit"
                onClick={handleClick}
              />
            </div>
          </form>
        </div>
      </div>
    );
  } else {
    return (
      <div className="FeaturedProduct">
        <div className="featuredProductTitleContainer">
          <h1 className="FeaturedProductTitle">Featured Product</h1>
        </div>
        <div className="featuredProductShow">
          <div className="featuredProductShowTop">
            <span className="featuredProductShowId">
              Featured Product not found
            </span>
          </div>
        </div>
      </div>
    );
  }
}

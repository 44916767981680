import { useEffect, useState } from "react";
import "./latestOrders.css";
import TimeAgo from "react-timeago";
import { getLatestOrders } from "../../redux/apiCalls";
import { useNavigate } from "react-router-dom";

export default function LatestOrders() {
  const Button = ({ type }) => {
    return <button className={"latestOrdersButton " + type}>{type}</button>;
  };
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await getLatestOrders();
        console.log(res);
        if (res !== null) {
          setOrders(res);
          setLoading(false);
        } else {
          setLoading(true);
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, []);

  const GoToOrder = (id) => {
    navigate(`/order/${id}`);
  };

  return (
    <div className="latestOrders">
      <h2>Latest Orders</h2>
      <table className="latestOrdersTable">
        <tr className="latestOrdersTr">
          <th className="latestOrdersTh">Customer</th>
          {/* <th className="latestOrdersTh">Product</th> */}
          <th className="latestOrdersTh">Date</th>
          <th className="latestOrdersTh">Price</th>
          <th className="latestOrdersTh">Status</th>
        </tr>
        {loading ? (
          <div className="loading">Loading...</div>
        ) : (
          orders.map((order) => (
            <tr
              className="latestOrdersTrOrder"
              key={order._id}
              onClick={() => GoToOrder(order._id)}
            >
              <td className="latestOrdersUser">{order.user_email}</td>
              {/* <td className="latestOrdersProduct">Macbook Pro</td> */}
              <td className="latestOrdersDate">
                <TimeAgo date={order.createdAt} />
              </td>
              <td className="latestOrdersPrice">AED {order.amount}</td>
              <Button type={`${order.status}`} />
            </tr>
          ))
        )}
      </table>
    </div>
  );
}

import "./discountList.css";

import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import { getDiscounts, searchDiscounts } from "../../redux/apiCalls";
import { Input } from "@mui/material";

const columns = [
  { field: "_id", headerName: "ID", width: 90 },
  { field: "name", headerName: "Name", width: 150 },
  { field: "description", headerName: "Description", width: 150 },
  { field: "discount_percentage", headerName: "Discount %", width: 150 },
  { field: "active", headerName: "Active", width: 150 },
  {
    field: "createdAt",
    headerName: "Created At",
    width: 150,
    valueFormatter: (params) => {
      return new Date(params.value).toLocaleDateString("en-AE");
    },
  },
  {
    field: "updatedAt",
    headerName: "Updated At",
    width: 150,
    valueFormatter: (params) => {
      return new Date(params.value).toLocaleDateString("en-AE");
    },
  },
  {
    field: "actions",
    headerName: "Actions",
    sortable: false,
    width: 100,
    renderCell: (rowData) => {
      return (
        <div className="actions">
          <Link to={"/discount/" + rowData.row._id}>
            <EditIcon className="discountListEdit" />
          </Link>
        </div>
      );
    },
  },
];

export default function DiscountList() {
  // get data from api using axios
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await getDiscounts();
        if (res !== null) {
          setData(res);
          setLoading(false);
        } else {
          setLoading(true);
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, []);

  async function handleSearch(value) {
    // if value is empty, trigger the useEffect to get all products
    if (value === "") {
      const res = await getDiscounts();
      if (res !== null) {
        setData(res);
        setLoading(false);
      } else {
        setLoading(true);
      }
    } else {
      const res = await searchDiscounts(value);
      if (res !== null) {
        setData(res);
        setLoading(false);
      } else {
        setLoading(true);
      }
    }
  }

  return (
    <div className="DiscountList">
      <div className="discountListTop">
        <h2>Discounts</h2>
        <Input
          type="text"
          placeholder="Search"
          className="ListSearch"
          onChange={(e) => handleSearch(e.target.value)}
        />
        <Link to={`/newDiscount`}>
          <button className="discountAddButton">New Discount</button>
        </Link>
      </div>
      <DataGrid
        rows={loading ? [] : data}
        columns={columns}
        autoPageSize={true}
        checkboxSelection
        disableSelectionOnClick
        getRowId={(row) => row._id}
      />
    </div>
  );
}

import "./home.css";
import FeaturedInfo from "../../components/featuredInfo/FeaturedInfo";
import LatestUsers from "../../components/latestUsers/LatestUsers";
import LatestOrders from "../../components/latestOrders/LatestOrders";
import { useEffect, useState } from "react";
import { checkServer } from "../../redux/apiCalls";

export default function Home() {
  const [serverOnline, setServerOnline] = useState(false);
  const [serverError, setServerError] = useState("Connecting to the server...");
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await checkServer();
        if (res.status === 200) {
          setServerOnline(true);
          setServerError("");
        } else {
          setServerOnline(false);
          console.log(res);
          setServerError("Server is offline");
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      {serverOnline ? (
        <div className="home">
          <FeaturedInfo />
          <div className="homeWidgets">
            <LatestUsers />
            <LatestOrders />
          </div>
        </div>
      ) : (
        <div className="home">
          <center>
            <h1>{serverError}</h1>
          </center>
        </div>
      )}
    </>
  );
}

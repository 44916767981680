import "./orderList.css";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import { getOrderById, getOrders, searchOrders } from "../../redux/apiCalls";
import { Input } from "@mui/material";

const columns = [
  { field: "_id", headerName: "ID", width: 90 },
  // { field: "user_id", headerName: "User ID", width: 150 },
  {
    field: "user_email",
    headerName: "Email",
    width: 150,
  },
  { field: "amount", headerName: "Amount", width: 150 },
  { field: "paymentOption", headerName: "Payment Option", width: 150 },
  {
    field: "shipping_address",
    headerName: "Shipping Address",
    width: 150,
    renderCell: (rowData) => {
      return (
        <div className="shipping_address">
          <p>{rowData.row.shipping_address.city}</p>
        </div>
      );
    },
  },
  { field: "status", headerName: "Status", width: 150 },
  {
    field: "createdAt",
    headerName: "Created At",
    width: 150,
    valueFormatter: (params) => {
      return new Date(params.value).toLocaleString("en-AE");
    },
  },
  {
    field: "updatedAt",
    headerName: "Updated At",
    width: 150,
    valueFormatter: (params) => {
      return new Date(params.value).toLocaleString("en-AE");
    },
  },
  {
    field: "actions",
    headerName: "Actions",
    sortable: false,
    width: 100,
    renderCell: (rowData) => {
      return (
        <div className="actions">
          <Link to={"/order/" + rowData.row._id}>
            <EditIcon className="orderListEdit" />
          </Link>
          {/* <DeleteIcon className="orderListDelete" /> */}
        </div>
      );
    },
  },
];

export default function OrderList() {
  // get data from api using axios
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await getOrders();
        if (res !== null) {
          setData(res);
          setLoading(false);
        } else {
          setLoading(true);
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, []);

  async function handleSearch(value) {
    // if value is empty, trigger the useEffect to get all products
    if (value === "") {
      const res = await getOrders();
      if (res !== null) {
        setData(res);
        setLoading(false);
      } else {
        setLoading(true);
      }
    } else {
      const res = await searchOrders(value);
      if (res !== null) {
        setData(res);
        setLoading(false);
      } else {
        setLoading(true);
      }
    }
  }

  async function handleSearchByID(value) {
    // if value is empty, trigger the useEffect to get all products
    if (value === "") {
      const res = await getOrders();
      if (res !== null) {
        setData(res);
        setLoading(false);
      } else {
        setLoading(true);
      }
    } else {
      const res = await getOrderById(value);
      if (res !== null) {
        // convert the object to array
        let arr = [];
        arr.push(res);
        setData(arr);
        setLoading(false);
      } else {
        setLoading(true);
      }
    }
  }

  return (
    <div className="OrderList">
      <div className="orderListTop">
        <h2>Orders</h2>
        <Input
          type="text"
          placeholder="Search by ID"
          className="ListSearch"
          onChange={(e) => handleSearchByID(e.target.value)}
        />
        <Input
          type="text"
          placeholder="Search"
          className="ListSearch"
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>
      <DataGrid
        rows={loading ? [] : data}
        columns={columns}
        autoPageSize={false}
        checkboxSelection
        disableSelectionOnClick
        getRowId={(row) => row._id}
        // rowsPerPageOptions={[10, 20, 50, 100]} and set default to 10
        rowsPerPageOptions={[10, 20, 50, 100]}
      />
    </div>
  );
}

import "./newDiscount.css";

import { useState } from "react";
import { TextField } from "@mui/material";
import { createDiscount } from "../../redux/apiCalls";

export default function NewDiscount() {
  const [state, setState] = useState({});

  const handleChange = (e) => {
    setState((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleClick = async (e) => {
    e.preventDefault();
    const discount = {
      name: state.name,
      description: state.description,
      discount_percentage: state.discount_percentage,
      start_date: state.start_date,
      end_date: state.end_date,
    };
    try {
      let res = await createDiscount(discount);
      if (res !== undefined && res !== null && res !== "" && res !== false) {
        alert("Discount created successfully");
        window.location.replace("/discounts");
      } else {
        alert("Discount creation failed");
      }
    } catch (err) {
      console.log(err);
    }
  };
  console.log(state);
  return (
    <div className="NewDiscount">
      <form className="discountForm">
        <div className="newDiscountMiddle">
          <div className="newDiscountItem">
            <label>Discount Name:</label>
            <TextField
              id="newDiscountName"
              placeholder="Discount name"
              name="name"
              value={state.name}
              onChange={handleChange}
              margin="normal"
              variant="standard"
              className="newDiscountInput"
            />
          </div>
          <div className="newDiscountItem">
            <label>Discount Description:</label>
            <TextField
              id="newDiscountDescription"
              placeholder="Discount Description"
              name="description"
              value={state.description}
              onChange={handleChange}
              margin="normal"
              variant="standard"
              className="newDiscountInput"
            />
          </div>
          <div className="newDiscountItem">
            <label>Discount Percentage:</label>
            <TextField
              id="newDiscountPercentage"
              placeholder="Discount Percentage"
              name="discount_percentage"
              type={"number"}
              value={state.discount_percentage}
              onChange={handleChange}
              margin="normal"
              variant="standard"
              className="newDiscountInput"
            />
          </div>
          <div className="newDiscountItem">
            <label>Discount Start Date:</label>
            <TextField
              id="newDiscountStartDate"
              placeholder="Discount Start Date"
              name="start_date"
              type={"datetime-local"}
              value={state.start_date}
              onChange={handleChange}
              margin="normal"
              variant="standard"
              className="newDiscountInput"
            />
          </div>
          <div className="newDiscountItem">
            <label>Discount End Date:</label>
            <TextField
              id="newDiscountEndDate"
              placeholder="Discount End Date"
              name="end_date"
              type={"datetime-local"}
              value={state.end_date}
              onChange={handleChange}
              margin="normal"
              variant="standard"
              className="newDiscountInput"
            />
          </div>
        </div>
        <div className="newDiscountBottom">
          <input
            type="submit"
            value="Submit"
            className="newDiscountSubmit"
            onClick={handleClick}
          />
        </div>
      </form>
    </div>
  );
}

import Topbar from "./components/topbar/Topbar";
import Sidebar from "./components/sidebar/Sidebar";
import "./App.css";
import Home from "./pages/home/Home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import UserList from "./pages/userList/UserList";
import User from "./pages/user/User";

import ProductList from "./pages/productList/ProductList";
import Product from "./pages/product/Product";
import NewProduct from "./pages/newProduct/NewProduct";

import NotFound from "./pages/notFound/NotFound";
import CategoryList from "./pages/categoryList/CategoryList";
import Category from "./pages/category/Category";
import NewCategory from "./pages/newCategory/NewCategory";

import DiscountList from "./pages/discountList/DiscountList";
import Discount from "./pages/discount/Discount";
import NewDiscount from "./pages/newDiscount/NewDiscount";

import OrderList from "./pages/orderList/OrderList";
import ApprovedOrders from "./pages/approvedOrders/ApprovedOrders";
import PendingOrders from "./pages/pendingOrders/PendingOrders";
import DeclinedOrders from "./pages/declinedOrders/DeclinedOrders";

import Login from "./pages/login/Login";

import { Outlet, Navigate } from "react-router-dom";
import LoginSuccess from "./pages/login/LoginSuccess";

import { useDispatch, useSelector } from "react-redux";
import { logout } from "./redux/userRedux";
import TermsAndConditions from "./pages/termsAndConditions/TermsAndConditions";
import AdminList from "./pages/adminList/AdminList";
import BlockedUserList from "./pages/blockedUserList/BlockedUserList";
import BrandList from "./pages/brandList/BrandList";
import NewBrand from "./pages/newBrand/NewBrand";
import Brand from "./pages/brand/Brand";
import FeaturedProductsList from "./pages/featuredProductsList/FeaturedProductsList";
import Order from "./pages/order/Order";
import PrivacyPolicy from "./pages/privacyPolicy/PrivacyPolicy";
import ReturnRefund from "./pages/returnRefund/ReturnRefund";
import AboutUs from "./pages/aboutUs/AboutUs";
import FeaturedProduct from "./pages/featuredProduct/FeaturedProduct";
import NewFeaturedProduct from "./pages/newFeaturedProduct/NewFeaturedProduct";
import Configs from "./pages/configs/Configs";

// Needs fixing: Check id user is logged in and is admin
const AppLayout = ({ admin }) =>
  admin ? (
    <>
      <Topbar />
      <div className="container">
        <Sidebar />
        <Outlet />
      </div>
    </>
  ) : (
    <Navigate to="/login" />
  );

function App() {
  // ============================================================
  // ========== This methos didn't work ============
  // ========== PersistGate should delay rendering until the store is ready but it didn't do that 😑 ============
  // ========== So I had to use useSelector to check if the user is logged in (worked like a charm 😀) ============
  // ============================================================
  // if (
  //   JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
  //     .currentUser !== null
  // ) {
  //   isLoggedIn = true;
  //   admin = JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
  //     .currentUser.isAdmin;
  // }

  const dispatch = useDispatch();
  // check if localstorage expirate date is greater than current date
  // if not, remove user from localstorage
  if (localStorage.getItem("expirationTime")) {
    // const user = JSON.parse(localStorage.getItem("user"));
    const expirateDate = localStorage.getItem("expirationTime");
    const currentDate = new Date().getTime();
    // convert current milliseconds date to normal date
    // for testing purposes
    // const currentDatee = new Date().toUTCString();
    // const expirateDatee = new Date(expirateDate*1).toUTCString();
    // console.log("currentDatee: " + currentDatee);
    // console.log("expirateDatee: " + expirateDatee);
    // console.log(currentDate);
    // console.log(expirateDate);
    if (expirateDate < currentDate) {
      console.log("user expired");
      // create dispatch to logout user
      dispatch(logout());
    } else {
      console.log("user is logged in");
    }
  }

  var admin = false;

  const user = useSelector((state) => state.user.currentUser);
  if (user !== null) {
    admin = user.isAdmin;
  }

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<AppLayout admin={admin} />}>
          <Route path="/" element={<Home />} />
          <Route path="/users" element={<UserList />} />
          <Route path="/admins" element={<AdminList />} />
          <Route path="/blockedUsers" element={<BlockedUserList />} />
          <Route path="/user/:id" element={<User />} />
          <Route path="/products" element={<ProductList />} />
          <Route path="/product/:id" element={<Product />} />
          <Route path="/newProduct" element={<NewProduct />} />
          <Route path="/categories" element={<CategoryList />} />
          <Route path="/category/:id" element={<Category />} />
          <Route path="/newCategory" element={<NewCategory />} />
          <Route path="/brands" element={<BrandList />} />
          <Route path="/newBrand" element={<NewBrand />} />
          <Route path="/brand/:id" element={<Brand />} />
          <Route path="/featuredProducts" element={<FeaturedProductsList />} />
          <Route path="/featuredProduct/:id" element={<FeaturedProduct />} />
          <Route path="/newFeaturedProduct" element={<NewFeaturedProduct />} />
          <Route path="/orders" element={<OrderList />} />
          <Route path="/approvedOrders" element={<ApprovedOrders />} />
          <Route path="/pendingOrders" element={<PendingOrders />} />
          <Route path="/declinedOrders" element={<DeclinedOrders />} />
          <Route path="/order/:id" element={<Order />} />
          <Route path="/discounts" element={<DiscountList />} />
          <Route path="/newDiscount" element={<NewDiscount />} />
          <Route path="/discount/:id" element={<Discount />} />

          <Route path="/termsAndConditions" element={<TermsAndConditions />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/returnRefund" element={<ReturnRefund />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/configs" element={<Configs />} />
        </Route>

        <Route path="/loginSuccess" element={<LoginSuccess />} />
        <Route path="404" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
